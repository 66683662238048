<template>
  <f7-page no-toolbar no-swipeback name="withdraw" class="page-withdraw"  @page:beforein="onPageBeforeIn">
    <f7-navbar :title="$t('withdrawal_0001')" back-link :no-hariline="true"></f7-navbar>
    <template v-if="withdrawLoadStatus">
      <f7-block class="block-withdraw">
        <div class="form">
          <div class="form-item">
            <div class="item-inner">
              <div class="item-title item-label">{{ $t("withdrawal_0002") }}</div>
              <div class="item-input-wrap">
                <input type="text" readonly :value="withdrawInfo.Balance | formatCurrency" />
              </div>
            </div>
          </div>
          <div class="form-item">
            <a class="smart-select smart-select-init" data-open-in="sheet">
              <select v-model="withdrawForm.MemberBankCardID">
                <option class="aa" v-for="item in memberBankCardList" :key="item.MemberBankCardID"
                  :value="item.MemberBankCardID">{{ item.BankAccountNo }} - {{ item.BankName }}</option>
              </select>
              <div class="item-content">
                <div class="item-inner">
                  <div class="item-title">{{ $t("withdrawal_0003") }}</div>
                  <div v-if="memberBankCardList.length > 0" class="item-after atm">{{
                    memberBankCardList[0].BankAccountNo }} - {{ memberBankCardList[0].BankName }}</div>
                  <div v-else class="item-after atm">--</div>
                </div>
              </div>
            </a>
          </div>

          <div class="form-item">
            <div class="item-inner">
              <div class="item-title item-label">{{ $t("withdrawal_0004") }}</div>
              <div class="item-input-wrap">
                <input v-if="selectedBankCardHolder" type="text" readonly :value="selectedBankCardHolder" />
                <input v-else type="text" readonly :value="'--'" />
              </div>
            </div>
          </div>
          <!-- <div class="form-item">
            <div class="item-inner">
              <div class="item-title item-label">{{ $t('withdrawal_0005') }}</div>
              <div class="item-input-wrap">
                <input type="text" readonly :value="selectedBankCardBankName">
              </div>
            </div>          
          </div> -->

          <div class="form-item">
            <div v-if="enableWithdrawQuickAmount">
              <a class="smart-select smart-select-init" data-open-in="sheet">
                <select v-model="withdrawForm.WithdrawalAmount">
                  <option class="aa" v-for="(item, index) in withdrawInfo.WithdrawQuickAmountList" :key="index"
                    :value="item">{{ item | formatCurrency2 }}</option>
                </select>
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title item-label">{{ $t("withdrawal_0006") }}</div>
                    <div class="item-after atm atms">{{ withdrawInfo.WithdrawQuickAmountList[0] | formatCurrency2 }}
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="item-inner" v-else>
              <div class="item-title item-label">{{ $t("withdrawal_0006") }}</div>
              <div class="item-input-wrap">
                <input type="number" :placeholder="$t('withdrawal_0007')" v-model="withdrawForm.WithdrawalAmount" />
              </div>
            </div>
          </div>

          <div class="form-item">
            <div class="item-inner">
              <div class="item-title item-label">{{ $t("withdrawal_0008") }}</div>
              <div class="item-input-wrap">
                <input type="password" :placeholder="$t('withdrawal_0009')" v-model="withdrawForm.SecurityPass" />
              </div>
            </div>
          </div>
        </div>
      </f7-block>
      <f7-block class="block-table" v-show="1 == 0">
        <f7-row>
          <f7-col width="50">{{ $t("withdrawal_0010") }}</f7-col>
          <f7-col width="50">{{ $t("withdrawal_0011") }}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="50">{{ handlingFee <= 0 ? "--" : handlingFee }}</f7-col>
              <f7-col width="50">{{ withdrawInfo.ServiceFee }}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="50">{{ $t("withdrawal_0012") }}</f7-col>
          <f7-col width="50">{{ $t("withdrawal_0013") }}</f7-col>
        </f7-row>
        <f7-row>
          <f7-col width="50">{{ withdrawInfo.Bonus || "--" }}</f7-col>
          <f7-col width="50">
            <span class="desirableAmt">{{ desirableAmt < 0 ? "0" : desirableAmt || "--" }}</span>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block class="block-buttons">
        <f7-row tag="p">
          <!-- <f7-col tag="span">
            <f7-button popup-open=".turnoverAuditList-popup" large raised  class="button-single">{{ $t('withdrawal_0014') }}</f7-button>
          </f7-col> -->
          <f7-col tag="span">
            <f7-button large raised fill class="button-single-yellow" @click="withdrawSubmit">{{ $t("withdrawal_0015")
              }}</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block class="block-tip">
        <p class="tip-title">{{ $t("common_0004") }}:</p>
        <p>{{ $t("withdrawal_0016") }}</p>
        <div v-if="vipflag=='true'">
          <p>{{ $t("withdrawal_0039") }}</p>
          <p>{{ $t('withdrawal_0040') }}</p>
        </div>
        <div v-else>
          <p>{{ $t("withdrawal_0017") }}</p>
          <p>{{ $t('withdrawal_0018') }}</p>
        </div>
        <!-- <p>{{ $t('withdrawal_0019') }}</p> -->
      </f7-block>
    </template>
    <template v-else>
      <f7-block class="block-notw">
        <p>{{ notWithdrawText }}</p>
      </f7-block>
    </template>

    <f7-popup v-show="showAuditListPopup" class="turnoverAuditList-popup" @popup:open="auditPopupOpen"
      @popup:close="auditPopupClosed">
      <f7-page infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
        <f7-navbar :title="$t('withdrawal_0014')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block v-if="auditDataList.length > 0">
          <f7-card v-for="data in auditDataList" :key="data.Id">
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0020") }}:</f7-col>
              <f7-col width="70">
                <span class="span-white">{{ data.DepositTs.replace("T", " ") }}</span>
              </f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0021") }}:</f7-col>
              <f7-col width="70">{{ data.DepositAmount != 0 ? data.DepositAmount : "--" }}</f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0022") }}:</f7-col>
              <f7-col width="70" v-if="data.DepositAmount != 0">{{ data.DepositTO }}/{{ data.DepositTargetTO }}</f7-col>
              <f7-col width="70" v-if="data.DepositAmount == 0">--</f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0023") }}:</f7-col>
              <f7-col width="70">{{ data.DepositAuditGameTypes | filterAuditType }}</f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0024") }}:</f7-col>
              <f7-col width="70">
                <span class="span-pass">{{ data.DepositTO >= data.DepositTargetTO && data.DepositAmount != 0 ?
                  this.$t("common_0025") : data.DepositAmount != 0 ? data.ServiceFee : "--" }}</span>
              </f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0025") }}:</f7-col>
              <f7-col width="70">{{ data.Bonus == 0 ? "--" : data.Bonus }}</f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0026") }}:</f7-col>
              <f7-col width="70" v-if="data.Bonus != 0">{{ data.BonusTO }}/{{ data.BonusTargetTO }}</f7-col>
              <f7-col width="70" v-if="data.Bonus == 0">--</f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0023") }}:</f7-col>
              <f7-col width="70">{{ data.BonusAuditGameTypes | filterAuditType }}</f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("withdrawal_0027") }}:</f7-col>
              <f7-col width="70">
                <span
                  :class="data.BonusTO >= data.BonusTargetTO && data.Bonus != 2 ? 'span-pass' : data.Bonus == 0 ? '' : 'span-reject'">{{
                    data.BonusTO >= data.BonusTargetTO && data.Bonus != 2 ? this.$t("common_0025") : data.Bonus == 1 ?
                  "--" :
                  data.Bonus }}</span>
              </f7-col>
            </f7-row>
            <f7-row no-gap>
              <f7-col width="30">{{ $t("common_0026") }}:</f7-col>
              <f7-col width="70">
                <span :class="data.Status == 2 ? 'span-pass' : 'span-reject'">{{ data.Status == 2 ?
                  this.$t("common_0025") :
                  this.$t("common_0027") }}</span>
              </f7-col>
            </f7-row>
          </f7-card>
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import { getWithdrawInfo, checkWithdraw, requestWithdraw, getTurnoverauditlist } from "../../../axios/api";
import { getCookie, getStore } from "../../../config/utils";
export default {
  components: {},
  props: {},
  data() {
    return {
      withdrawLoadStatus: false,
      withdrawInfo: {},
      withdrawForm: {
        WithdrawalAmount: "",
        WithdrawalFee: "",
        ServiceFee: "",
        DiffBonus: "",
        FinalAmount: "",
        SecurityPass: "",
        MemberBankCardID: 0,
      },
      minAmount: 0,
      maxAmount: 0,
      checkTurnover: false,
      notWithdrawText: "",
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      auditDataList: [],
      memberBankCardList: [],
      showAuditListPopup: true,
      enableWithdrawQuickAmount: true,
      vipflag: '',
    };
  },
  watch: {},
  computed: {
    handlingFee() {
      if (!this.withdrawLoadStatus || !this.withdrawForm.WithdrawalAmount) return "";
      let result = ((this.withdrawForm.WithdrawalAmount - this.withdrawInfo.ServiceFee - this.withdrawInfo.Bonus) * this.withdrawInfo.WithdrawalFeeRate) / 100;
      return result;
    },
    desirableAmt() {
      if (!this.withdrawLoadStatus || !this.withdrawForm.WithdrawalAmount) return "";
      let result = Math.floor(parseInt(this.withdrawForm.WithdrawalAmount) - this.withdrawInfo.ServiceFee - this.withdrawInfo.Bonus - this.handlingFee);
      return result;
    },
    selectedBankCardHolder() {
      for (var k = 0, length = this.memberBankCardList.length; k < length; k++) {
        var item = this.memberBankCardList[k];
        if (item.MemberBankCardID == this.withdrawForm.MemberBankCardID) {
          return item.HolderName;
        }
      }
      return "";
    },
    selectedBankCardBankName() {
      for (var k = 0, length = this.memberBankCardList.length; k < length; k++) {
        var item = this.memberBankCardList[k];
        if (item.MemberBankCardID == this.withdrawForm.MemberBankCardID) {
          return item.BankName;
        }
      }
      return "";
    },
  },
  methods: {
    onPageBeforeIn(){
      this.vipflag = getStore("isDefaultVIPLevel");
    },
    memberWithdrawInfo() {
      const self = this;
      getWithdrawInfo().then((data) => {
        if (data.Code === "NoError") {
          self.withdrawInfo = data.Data;
          self.withdrawLoadStatus = true;
          self.withdrawForm.DiffBonus = data.Data.Bonus;
          self.withdrawForm.ServiceFee = data.Data.ServiceFee;
          self.memberBankCardList = data.Data.BankCardList;
          self.enableWithdrawQuickAmount = data.Data.EnableWithdrawQuickAmount;
          if (data.Data.EnableWithdrawQuickAmount) {
            self.withdrawForm.WithdrawalAmount = data.Data.WithdrawQuickAmountList[0];
          }

          if (self.memberBankCardList.length > 0) {
            self.withdrawForm.MemberBankCardID = self.memberBankCardList[0].MemberBankCardID;
          }
          if (!self.withdrawInfo.BankAccountNo) {
            // self.$f7.params.dialog.buttonOk = this.$t('withdrawal_0028');
            self.$f7.dialog.confirm(
              this.$t("withdrawal_0029"),
              this.$t("withdrawal_0030"),
              () => {
                self.$f7.params.dialog.buttonOk = this.$t("common_0001");
                self.$f7router.navigate("/member-center/bank-info/2/");
              },
              () => {
                self.$f7router.back();
              }
            );
          }
        }
      });
    },
    checkWithdrawStatus() {
      const self = this;
      checkWithdraw().then((data) => {
        if (data.Code === "NoError" && data.Data.IsWithdrawal) {
          self.minAmount = data.Data.MinAmount || 0;
          self.maxAmount = data.Data.MaxAmount || 100000000;
          self.checkTurnover = data.Data.CheckTurnover;
          self.memberWithdrawInfo();
        } else {
          self.withdrawLoadStatus = false;
          let withdrawText = {
            Verify: this.$t("withdrawal_0031"),
            Times: this.$t("withdrawal_0032"),
            MaxAmount: this.$t("withdrawal_0033"),
            LessMinAmount: this.$t("withdrawal_0034"),
            NotAllowWithdraw: this.$t("withdrawal_0035"),
          };
          self.notWithdrawText = withdrawText[data.Data.Type];
        }
      });
    },
    withdrawSubmit() {
      const self = this;
      self.withdrawForm.FinalAmount = self.desirableAmt;
      self.withdrawForm.WithdrawalFee = self.handlingFee ? self.handlingFee : 0;
      if (self.withdrawForm.WithdrawalAmount > self.withdrawInfo.Balance) {
        self.$f7.dialog.alert(this.$t("withdrawal_0035"), this.$t("common_0004"));
        return false;
      }
      if (!self.withdrawForm.WithdrawalAmount) {
        self.$f7.dialog.alert(this.$t("withdrawal_0007"), this.$t("common_0004"));
        return false;
      }
      if (self.withdrawForm.WithdrawalAmount > self.withdrawInfo.Balance || self.withdrawForm.WithdrawalAmount < self.withdrawInfo.MinWithdrawalAmount) {
        self.$f7.dialog.alert(this.$t("common_withdraw_balance_format", [self.withdrawInfo.MinWithdrawalAmount, self.withdrawInfo.Balance]), this.$t("common_0004"));
        return false;
      }
      if (!self.withdrawForm.SecurityPass) {
        self.$f7.dialog.alert(this.$t("withdrawal_0037"), this.$t("common_0004"));
        return false;
      }
      const d = { data: JSON.stringify(self.withdrawForm) };
      requestWithdraw(d).then((data) => {
        if (data.Code === "NoError") {
          self.$f7.dialog.alert(this.$t("withdrawal_0038"), this.$t("common_0004"), () => {
            self.checkWithdrawStatus();
            self.withdrawForm.WithdrawalAmount = "";
            self.withdrawForm.SecurityPass = "";
          });
        } else {
          self.$f7.dialog.alert(data.Data, this.$t("common_0004"));
        }
      });
    },
    auditPopupOpen() {
      this.turnoverAuditList(this.pageNow);
      this.showAuditListPopup = true;
    },
    auditPopupClosed() {
      this.pageNow = 1;
      this.auditDataList = [];
      this.allowInfinite = true;
      this.showPreloader = true;
      this.$f7.$(".infinite-scroll-preloader").show();
      this.showAuditListPopup = false;
    },
    turnoverAuditList(pageNow) {
      const self = this;
      const param = { Status: [1, 2], PageNow: pageNow, PageSize: 5 };
      const d = { data: JSON.stringify(param) };
      getTurnoverauditlist(d).then((data) => {
        self.auditDataList = self.auditDataList.concat(data.Data.Data);
        self.pageTotal = data.Data.Total;
        if (data.Data.Data.length <= data.Data.Total) {
          self.showPreloader = false;
        }
      });
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.auditDataList.length >= self.pageTotal) {
          self.$f7.$(".infinite-scroll-preloader").hide();
          return;
        }
        self.pageNow += 1;
        self.turnoverAuditList(self.pageNow);

        self.allowInfinite = true;
      }, 1000);
    },
  },
  created() {
    this.checkWithdrawStatus();

  },
  mounted() { },
  updated() { },
};
</script>
<style lang="less" scoped>
.page-withdraw {
  --f7-page-toolbar-bottom-offset: 0;

  .item-radio {
    .item-title {
      font-size: 12px;
    }
  }

  .block-withdraw {
    margin: 10px 0;
    padding: 0 10px;

    .form {
      .form-item {
        .atm {
          font-size: 13px;
          background: #fafafa;
          border: 1px solid #1b2a3b10;
          padding: 10px;
          border-radius: 6px;
          margin: 2px 0 10px;
          max-width: 100%;
        }


        .item-inner {
          .item-title {
            color: #464646;
            font-size: 14px;
          }

          .item-input-wrap {
            // width: 100%;
            background: #fafafa;
            border: 1px solid #1b2a3b10;
            padding: 10px;
            border-radius: 6px;
            margin: 2px 0 10px;

            input {
              width: 100%;
            }
          }

          .smart-select .item-after {
            //max-width: unset;
            width: 10px;
          }
        }
      }
    }

    .list-withdraw {
      margin: 0;

      ul {
        background-color: transparent;

        .item-input {
          .item-inner {
            .item-title {
              padding-top: 8px;
              color: #464646;
              width: 30%;
            }

            input {
              color: #464646;
            }
          }

          .item-inner:after {
            height: 0;
          }
        }

        .smart-select {
          .item-content {
            .item-inner {
              .item-title {
                font-size: 13px;
                //padding-top: 8px;
                color: #464646;
                width: 30%;
              }

              .item-after {
                width: 70%;
                color: #464646;
                font-size: 14px;
              }
            }

            .item-inner:after {
              height: 0;
            }
          }
        }

        .smart-select.active-state {
          background-color: transparent;
        }
      }

      ul:before,
      ul:after {
        height: 0;
      }
    }
  }

  .block-table {
    background: #38538d;
    color: #fff;
    margin: 5px 20px 0;
    margin-bottom: 10px;
    font-size: 12px;
    padding: 5px 5px;
    border: 1px solid #295d88;
    border-radius: 5px;

    .row {
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 14px;

      .desirableAmt {
        color: #33cd5f;
      }
    }
  }

  .block-notw {
    p {
      color: #0064a1;
      text-align: center;
    }
  }

  .block-buttons {
    //padding: 0 20px;
    margin: 30px 0 20px;

    .button-detail {
      background: #eca954;
    }

    .button-submit {
      border: 1px solid #be9a44;
      color: #be9a44;
      background: #111f42;
    }
  }

  .block-tip {
    margin: 0;

    p {
      color: #464646;
    }

    .tip-title {
      color: #464646;
    }
  }
}
</style>
