<template>
  <f7-page @page:beforein="onPageBeforeIn" no-swipeback name="order-center" class="page-order-center" style="padding-bottom: var(--f7-safe-area-bottom)">
    <f7-navbar>
      <!-- <f7-nav-left>
        <f7-link @click="back">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left> -->
      <f7-nav-title style="">{{ $t("member_center_0012") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- Swiper banner -->
    <!-- <f7-block class="block-banner home-banner"> -->

    <div class="top">
      <div class="curr-time">{{ utcMinusFourTime | time_local }}</div>
      <div class="top-stat-list">
        <div class="item-content">
          <img :src="`../../../static/images/yt999/ico_cal${totalWinlose > 0 ? '1' : ''}.png`" />
          <div class="p1">
            <span>{{ $t("winloserp_0017") }}</span>
          </div>
          <div class="p2">
            <span class="symbol">{{ currencySymbol }}</span>
            {{ totalWinlose | formatCurrency }}
          </div>
        </div>
        <div class="item-content">
          <img :src="`../../../static/images/yt999/ico_avail${totalBalance > 0 ? '1' : ''}.png`" />
          <div class="p1">
            <span>{{ $t("winloserp_0018") }}</span>
          </div>
          <div class="p2">
            <span class="symbol">{{ currencySymbol }}</span>
            {{ totalBalance | formatCurrency }}
            <span v-show="totalBetAmount != 0" style="color: #9f9898">({{ totalBetAmount | formatCurrency }})</span>
          </div>
        </div>
        <div class="item-content">
          <img :src="`../../../static/images/yt999/ico_order${totalBetCount > 0 ? '1' : ''}.png`" />
          <div class="p1">
            <span>{{ $t("winloserp_0019") }}</span>
          </div>
          <div class="p2">{{ totalBetCount }}</div>
        </div>
        <div class="item-content">
          <img :src="`../../../static/images/yt999/ico_totad${dailyOrderCountLimit - totalBetCount == 0 ? '' : '1'}.png`" />
          <div class="p1">
            <span>{{ $t("winloserp_0020") }}</span>
          </div>
          <div class="p2">{{ dailyOrderCountLimit - totalBetCount }}</div>
        </div>
      </div>
      <!-- <div class="top-stat">
        <ul>
            <li>
              <img src="../../../static/images/yt999/ico_cal.png">
              <p class="p1">{{ $t('winloserp_0017') }}</p>
              <p class="p2"><span class="symbol">{{ currencySymbol}}</span>{{ totalWinlose }}</p>
            </li>
            <li>
              <img src="../../../static/images/yt999/ico_avail.png">
              <p class="p1">{{ $t('winloserp_0018') }}</p>
              <p class="p2"><span class="symbol">{{ currencySymbol}}</span>{{ totalBalance }}<span style="color: #9f9898;">({{ totalBetAmount }})</span></p>
            </li>
            <li>
              <img src="../../../static/images/yt999/ico_order.png">
              <p class="p1">{{ $t('winloserp_0019') }}</p>
              <p class="p2">{{ totalBetCount }}</p>
            </li>
            <li>
              <img src="../../../static/images/yt999/ico_totad.png">
              <p class="p1">{{ $t('winloserp_0020') }}</p>
              <p class="p2">{{ dailyOrderCountLimit }}</p>
            </li>
        </ul>
      </div> -->
      <!-- <div class="info" style="display: none;">
        <div class="list inline-labels">
          <ul>
            <li>
              <a class="item-link smart-select smart-select-init" data-open-in="sheet">
                <select v-model="winloseParams.DateRangeType" name="select-funds">
                  <option value=1>{{ $t('common_0034') }}</option>
                  <option value=11>{{ $t('common_0035') }}</option>
                  <option value=2>{{ $t('common_0036') }}</option>
                  <option value=12>{{ $t('common_0037') }}</option>
                  <option value=3>{{ $t('common_0038') }}</option>
                  <option value=13>{{ $t('common_0039') }}</option>
                </select>
                <div class="item-content">
                  <div class="">
                    <div class="">{{ $t('common_0034') }}</div>
                  </div>
                </div>
                
              </a>
            </li>
          </ul>
        </div>
        
      </div> -->

      <div class="copy">
        <span class="copybutton" @click="getRequestNewOrder()">{{ $t("winloserp_0012") }}</span>
      </div>
      <div class="list inline-labels orderlist">
        <ul>
          <li>
            <a class="item-link">
              <div class="item-content">
                <div class="item-inner" style="font-size: 15px" @click="$f7router.navigate('/member-center/order-search/')">
                  <div class="item-title">{{ $t("winloserp_0013") }}</div>
                  <div class="item-after">{{ $t("winloserp_0014") }}</div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <div class="statistics" v-for="item in gametransactiontopList" :key="item.TicketNo">
        <ul style="line-height: 22px; padding-bottom: 10px">
          <li v-show="item.WLStatus == 'P'" @click="goCheckPendingOrderPage(item.TicketNo)"><i class="f7-icons list-ico">greaterthan_square_fill</i></li>
          <div class="list-a">
            <li class="list-title">
              <span>{{ item.TicketNo }}</span>
            </li>
            <li class="list-ts">
              <span>{{ item.BetTs | time_local }}</span>
            </li>
          </div>
          <li class="list-img"><img :src="item.ProviderGameExtensionData" /></li>
          <li v-if="item.IsLuckyOrder == true" class="list-type-ico">
            <img src="../../../static/images/yt999/ticket_lucky.png" />
            <img />
          </li>
          <li v-else class="list-type-ico">
            <img src="../../../static/images/yt999/ticket_normal.png" />
            <img />
          </li>
          <li class="list-detail">
            <p class="p-name">{{ item.GameName }}</p>
            <p>× 1</p>
            <p>
              <span v-if="item.ShowPendingReviewStatus" class="col-yellow">({{ $t("betting_records_0021") }})</span>
              <span v-else>
                <span v-if="item.WLStatus == 'P'" class="col-yellow">({{ $t("betting_records_0003") }})</span>
                <span v-if="item.WLStatus == 'WA'" class="col-green">({{ $t("betting_records_0020") }})</span>
                <span v-if="item.WLStatus == 'WH'" class="col-blue">({{ $t("betting_records_0008") }})</span>
                <span v-if="item.WLStatus == 'C'" class="col-gray">({{ $t("betting_records_0004") }})</span>
              </span>
            </p>
            <p v-if="item.WLStatus !== 'C'">
              {{ $t("winloserp_0015") }}
              <span style="color: red">
                <span class="symbol">{{ currencySymbol }}</span>
                {{ item.APIWLAmount }}
              </span>
            </p>
            <p v-else>
              {{ $t("winloserp_0015") }}
              <span class="col-gray">
                <span class="symbol">{{ currencySymbol }}</span>
                0.00
              </span>
            </p>
          </li>
        </ul>
      </div>
      <div class="buttom-area"></div>
    </div>
  </f7-page>
</template>

<script>
import { getInfo, getmemberwinloserp, getcheckpendingorder, getrequestneworder, gametransactiontop10get, getCarouseladvertList } from "../../../axios/api";
import preloader from "../../template/preloader";
import { getCookie, getStore } from "../../../config/utils";
import { mapGetters } from "vuex";
export default {
  components: {
    preloader,
  },
  props: {},
  data() {
    return {
      currencySymbol: "",
      intervalId: null,
      utcMinusFourTime: "",
      info: {},
      dateRangeDesc: this.$t("common_0034"),
      winloseParams: {
        // StartDate: 20210101,
        // EndDate: 20210712,
        DateRangeType: 1,
      },
      showWinlose: false,
      winloseInfo: {},
      gametransactiontopList: [],
      totalBetAmount: 0,
      totalBalance: 0,
      totalWinlose: 0,
      totalBetCount: 0,
      dailyOrderCountLimit: 0,
      systemTime: "",
      estimatedCommissionToday: 0,
      link: "",
      getInfoStatus: false,
      bannerList: [],
      bannerBaseUri: "",
      bannerShow: false,
      swiperParams: {
        speed: 700,
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 8000,
        },
        lazy: {
          loadPrevNext: true,
        },
      },
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    updateTime() {
      var  timeZome = 0;
      timeZome = getStore("TimeZome");
      const date = new Date(); // Get current date/time
      const offset = timeZome * 60; // Offset in minutes (UTC-4 is 4 hours behind UTC)
      const utcMinusFourDate = new Date(date.getTime() + offset * 60000); // Apply offset

      // Format the date and time to display
      const formattedTime = this.formatDate(utcMinusFourDate);

      this.utcMinusFourTime = formattedTime + " (UTC+5)"; // Update data property with the formatted time
    },
    formatDate(date) {
      // Format the date with 12-hour time and AM/PM indicator
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true, // Use 12-hour time format
        timeZone: "UTC", // Display time in UTC
      };

      return date.toLocaleString("en-US", options).replace(",", "");
    },
    goCheckPendingOrderPage(ticketNo) {
      const self = this;
      self.$f7router.navigate("/member-center/order-submission/" + ticketNo + "/");
    },
    checkPendingOrder() {
      const self = this;
      let match = "";
      let flag = "";
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      getcheckpendingorder().then((data) => {
        if (typeof data.Data === "string") {
          match = data.Data.match(/\((.*?)\)/);
        }
        match == null ? (flag = "") : (flag = match[0]);
        if (data.Code === "NoError") {
          // if(data.Data !=null){
          //   self.$f7.dialog.confirm(
          //     this.$t('Order_0001'),
          //     this.$t('Order_0002'),
          //     () => {
          //       self.$f7router.navigate("/member-center/order-submission/" + data.Data.TicketNo + "/");
          //     },
          //     () => {
          //       // self.$f7router.back();
          //     }
          //   );
          // }
        } else if (flag === "(00)") {
          self.$f7.dialog.confirm(
            data.Data,
            this.$t("common_1025"),
            () => {
              self.$f7.$(".dialog-backdrop").css("background", "transparent");
              self.$f7router.navigate("/member-center/member-info/tab-identity/");
            },
            () => {
              //self.$f7router.back();
            }
          );
        } else if (flag === "(02)") {
          self.$f7.dialog.alert(data.Data, () => {
            self.$f7.$(".dialog-backdrop").css("background", "transparent");
            self.$f7router.navigate("/home/");
          });
        } else if (flag === "(03)") {
          self.$f7.dialog.confirm(
            data.Data,
            this.$t("common_1025"),
            () => {
              self.$f7.$(".dialog-backdrop").css("background", "transparent");
              self.$f7router.navigate("/member-center/member-info/tab-identity/");
            },
            () => {
              //self.$f7router.back();
            }
          );
        } else if (data.Code === "HaveNoPayAccount") {
          self.$f7.dialog.confirm(
            data.Data,
            this.$t("common_1025"),
            () => {
              self.$f7.$(".dialog-backdrop").css("background", "transparent");
              self.$f7router.navigate("/member-center/member-info/tab-personal/");
            },
            () => {
              //self.$f7router.back();
            }
          );
        } else {
          //提示
          self.$f7.$(".dialog-backdrop").css("background", "transparent");
          self.$f7.dialog.alert(data.Data);
        }
      });
    },
    selectGameTransactionTop10EventList() {
      const self = this;
      gametransactiontop10get().then((data) => {
        if (data.Code === "NoError") {
          self.gametransactiontopList = data.Data;
        }
      });
    },
    getRequestNewOrder() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = false;
      //self.$f7.$('.dialog-backdrop').css('background-color', 'transparent');
      let orderLoading = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("Order_0003"),
          cssClass: "dialog-preloadgame",
        })
        .open();
      getrequestneworder().then((data) => {
        setTimeout(() => {
          orderLoading.close();
          self.$f7.params.dialog.closeByBackdropClick = true;
          //self.$f7.$('.dialog-backdrop').css('background-color', 'rgba(0, 0, 0, 0.74)');
          if (data.Code === "NoError") {
            if (data.Data.ExistsPendingOrder) {
              self.$f7.dialog.confirm(
                this.$t("Order_0001"),
                this.$t("Order_0002"),
                () => {
                  self.$f7router.navigate("/member-center/order-submission/" + data.Data.TargetOrderTicketNo + "/");
                },
                () => {
                  // self.$f7router.back();
                }
              );
            } else {
              self.$f7router.navigate("/member-center/order-submission/" + data.Data.TargetOrderTicketNo + "/");
            }
          } else {
            //提示
            self.$f7.dialog.alert(data.Data);
          }
        }, 1500);
      });
    },
    selectMemberWinloseRP() {
      // this.$f7router.navigate(`/member-center/order-submission/`);
      const self = this;
      self.showWinlose = false;
      const param = { data: JSON.stringify(self.winloseParams) };
      getmemberwinloserp(param).then((data) => {
        if (data.Code === "NoError") {
          self.winloseInfo = data.Data;
          self.totalBetAmount = self.winloseInfo[0].TotalBetAmount;
          self.totalBalance = self.winloseInfo[0].TotalBalance;
          self.totalWinlose = self.winloseInfo[0].TotalWinlose;
          self.totalBetCount = self.winloseInfo[0].TotalBetCount;
          self.dailyOrderCountLimit = self.winloseInfo[0].DailyOrderCountLimit;
          self.systemTime = self.winloseInfo[0].SystemTime;
          self.showWinlose = true;
        }
      });
    },
    getBannerList(param) {
      const self = this;
      getCarouseladvertList(param).then((data) => {
        if (data.Code === "NoError") {
          self.bannerShow = true;
          self.bannerList = data.Data;
        }
      });
    },
    back() {
      if (this.$f7route.params.Id == 0) {
        // comes from home
        this.$f7router.navigate("/");
      } else if (this.$f7route.params.Id == 1) {
        //comes from member center
        this.$f7router.navigate("/member-center/");
      } else if (this.$f7route.params.Id == 2) {
        // comes from order conform page
        this.$f7router.navigate("/");
      } else {
        this.$f7router.navigate("/");
      }
    },
    onPageBeforeIn() {
      const self = this;
      self.$nextTick(() => {
        self.$f7.emit("myCustomEvent", "order-center");
      });
    },
  },
  created() {
    if (this.$f7route.params.Id != 2) {
      this.checkPendingOrder();
    }
    this.getBannerList({ data: 5 });
    this.selectMemberWinloseRP();
    this.selectGameTransactionTop10EventList();
    this.bannerBaseUri = getStore("ImagesURL") + window.configs.carouseladvertImg;
    this.currencySymbol = getStore("currencySymbol");
  },
  mounted() {
    this.updateTime(); // Call updateTime() when the component is mounted
    this.intervalId = setInterval(this.updateTime, 1000); // Update time every second
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style lang="less" scoped>
.page-order-center {
  color: #464646;

  .top {
    background: #fff;
    // border: 1px solid #bfb6b6;
    // border-radius: 4px;
    margin: 10px;
    font-size: 14px;

    .curr-time {
      text-align: center;
      margin-bottom: 10px;
      font-size: 15px;
      color: #404040;
    }

    .top-stat-list {
      margin: auto;
      width: 96%;
      text-align: center;
      background-color: #f3f7ff;
      border-radius: 6px;

      .item-content {
        position: relative;
        width: 90%;
        margin: auto;
        border-bottom: 1px solid #e9f0ff;
        text-align: left;
        height: 50px;
        padding-top: 17px;

        img {
          width: 50px;
          position: absolute;
          top: 10px;
          left: 0px;
        }

        .p1 {
          span {
            font-weight: bold;
          }

          padding-left: 55px;
          display: block;
          font-weight: 500;
        }

        .p2 {
          padding-left: 56px;
          display: block;
          color: var(--f7-THEME-color);
          //font-size: 16px;
        }
      }
    }

    .top-stat {
      margin: auto;
      width: 96%;
      text-align: center;
      background-color: #f3f7ff;
      border-radius: 6px;

      ul {
        line-height: 22px;

        li {
          width: 49%;
          display: inline-block;
          margin-top: 10px;

          img {
            width: 50px;
          }

          .p1 {
            margin-bottom: 0.5rem;
            font-weight: 500;
            margin-top: 0;
          }

          .p2 {
            line-height: 1.2;
            color: var(--f7-THEME-color);
            font-size: 16px;
            margin: -6px;
          }
        }
      }
    }

    .statistics {
      position: relative;
      margin-bottom: 10px;
      font-size: 12px;
      width: 96%;
      margin: auto;
      border: 1px solid #ddd9d9;
      border-radius: 4px;
      text-align: center;
      margin-top: 10px;
      background-color: #f9f9f9;

      .list-a {
        border-bottom: 1px solid #ededed;

        .list-title {
          width: 96%;
          line-height: 26px;
          display: inline-block;
          margin-top: 0px;
          text-align: left;
          margin-left: 40px;
          font-weight: bold;
          font-size: 13px;
          margin-bottom: -3px;
        }

        .list-ts {
          width: 90%;
          display: inline-block;
          text-align: left;
          margin-left: 39px;
          //border-bottom: 1px solid #ddd9d9;
          //margin: auto;
        }
      }

      .list-img {
        width: 30%;
        line-height: 30px;
        display: inline-block;
        margin-top: 10px;
        text-align: left;
        margin: 10px 0px 10px 0px;

        img {
          position: absolute;
          top: 50px;
          left: 3%;
          width: 90px;
          height: 97px;
          border: 1px solid #e1e1e1;
          border-radius: 4px;
        }
      }

      .list-type-ico {
        position: absolute;
        top: -3px;
        left: 10px;
      }

      .list-detail {
        width: 66%;
        line-height: 30px;
        display: inline-block;
        margin-top: 10px;

        p {
          font-weight: 500;
          line-height: 1;
          margin-top: 0;
          text-align: left;
          margin-bottom: 9px;
        }

        .p-name {
          font-weight: bold;
          font-size: 13px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .list-ico {
        position: absolute;
        right: 3%;
        top: 10px;
        color: var(--f7-button-bg-color);
      }

      .col-blue {
        color: #1576dd;
      }

      .col-green {
        color: #17903a;
      }

      .col-yellow {
        color: #ff7e00;
      }

      .col-gray {
        color: #777777;
      }

      .title {
        text-align: center;
        font-size: 18px;
        padding-top: 6px;
        font-weight: bold;
        color: #ad5c09;
      }

      .amount {
        text-align: center;
        font-size: 18px;
        padding-top: 6px;
        font-weight: bold;
        color: #ad5c09;
      }

      .desc {
        border-top: 1px solid #e0dbdb;
        margin-top: 10px;

        .p1 {
          margin: 10px;
          font-weight: bold;
        }

        .p2 {
          margin-left: 10px;
          margin-right: 10px;
          padding-bottom: 10px;
          word-wrap: break-word;
        }
      }
    }

    .buttom-area {
      height: 30px;
      margin-bottom: 30px;
    }

    .info {
      width: 90%;
      margin: 10px auto;

      div {
        background: #f1f1f1;
        border-radius: 5px;
        height: 28px;
        line-height: 28px;
        // margin-top: 6px;
        position: relative;

        span:nth-child(1) {
          float: left;
          margin-left: 10px;
        }

        span:nth-child(2) {
          color: #002fb3;
        }

        .refer {
          background: linear-gradient(#decabc, #b19785);
          color: #000;
          height: 26px;
          border-radius: 4px;
          line-height: 28px;
          width: 50px;
          display: inline-block;
          text-align: center;
          position: absolute;
          right: 1px;
          top: 1px;
        }
      }

      .list ul:before {
        background-color: transparent;
        width: 110%;
        margin-left: -10px;
      }

      .list ul:after {
        background-color: transparent;
        width: 110%;
        margin-left: -10px;
      }

      .list .item-content {
        display: flex;
        justify-content: space-around;
      }
    }

    .line {
      width: 95%;
      margin: auto;
      margin-top: 15px;
      border-top: 1px solid #1b336e;
    }

    .orderlist {
      margin: auto;
    }

    .orderlist ul:before {
      content: "";
      position: absolute;
      background-color: var(--f7-list-border-color);
      display: block;
      z-index: 15;
      top: 0;
      right: auto;
      bottom: auto;
      left: 0;
      height: 1px;
      width: 110%;
      margin-left: -10px;
      transform-origin: 50% 0%;
      transform: scaleY(calc(1 / 1));
      transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }

    .orderlist ul:after {
      content: "";
      position: absolute;
      background-color: var(--f7-list-border-color);
      display: block;
      z-index: 15;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 110%;
      margin-left: -10px;
      transform-origin: 50% 100%;
      transform: scaleY(calc(1 / 1));
      transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }

    .share {
      width: 90%;
      background: #2d2d2d;
      color: #b1b9ce;
      border-radius: 15px;
      height: 30px;
      line-height: 30px;
      margin: 15px auto;
      position: relative;
      padding-left: 10px;

      .sharedesc {
        width: 96%;
        color: #9cfaff;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .sharebutton {
        width: 80px;
        height: 28px;
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        display: inline-block;
        color: #000;
        background: linear-gradient(#decabc, #b19785);
        border-radius: 15px;
      }
    }

    .copy {
      width: 96%;
      height: 50px;
      //background: #2d2d2d;
      // background: #3e66ce;

      background-color: var(--f7-button-bg-color);
      border-radius: 7px;
      // height: 40px;
      // line-height: 30px;
      margin: 25px auto;
      position: relative;

      // padding-left: 10px;
      .sharedesc {
        color: #9cfaff;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .copybutton {
        color: var(--f7-button-text-color);
        font-weight: bold;
        width: 100%;
        height: 50px;
        line-height: 50px;
        position: absolute;
        top: 2px;
        text-align: center;
        display: inline-block;
        // color: #fff;
        background: var(--f7-THEME-color);
        border-radius: var(--f7-list-inset-border-radius);
        font-size: 17px;
        //border-radius: 15px;
        //border: #000 solid 1px;
      }
    }
  }

  .middle {
    border-top: 1px solid #bfbfbf;

    .wallet {
      background: url("../../../static/images/yt999/referrer/icon-up.png") bottom no-repeat, url("../../../static/images/yt999/referrer/highlight.png") bottom #efefef no-repeat;
      background-size: 100%;
      padding-top: 15px;
      width: 50%;
      position: relative;

      > div {
        margin-bottom: 15px !important;
      }

      color: #101010;

      .rebtn {
        background: linear-gradient(#decabc, #b19785);
        border-radius: 4px;
        width: 82%;
        height: 30px;
        line-height: 30px;
        color: #000;
        margin: auto;
      }
    }

    .rightafter::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 2px;
      height: 128px;
      background: url("../../../static/images/yt999/navafter.png") bottom no-repeat;
      background-size: 100% 100%;
    }
  }

  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    color: #101010;

    .s-title {
      font-size: 12px;
    }
  }
}
</style>
