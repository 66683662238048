<template>
  <f7-page no-toolbar no-swipeback name="order-search" class="page-betting-search" @page:beforeremove="onPageBeforeRemove">
    <f7-navbar :title="$t('betting_records_0001')" back-link :no-hariline="true"></f7-navbar>
    <f7-block class="block-search">
      <!-- <f7-list-input label="Birthday" type="date" defaultValue="2014-04-30" placeholder="Please choose...">
      </f7-list-input> -->
      <div class="form">
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0029") }}</div>
            <div class="item-input-wrap">
              <input style="text-align: left" type="date" id="dateF" v-model="BeginDay" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="item-inner">
            <div class="item-title item-label">{{ $t("common_0030") }}</div>
            <div class="item-input-wrap">
              <input style="text-align: left" type="date" id="dateT" v-model="EndDay" />
            </div>
          </div>
        </div>
      </div>
    </f7-block>
    <div class="list inset button-search">
      <ul>
        <li>
          <a href="#" class="list-button color-black" @click="searchSubmit">{{ $t("common_0028") }}</a>
        </li>
      </ul>
    </div>
    <div class="curr-time">{{ utcMinusFourTime | time_local }}</div>
  </f7-page>
</template>

<script>
import { getCookie, getStore } from "../../../config/utils";
import moment from "moment";
import Rolldate from "rolldate";
import { getProvidergame } from "../../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      intervalId: null,
      utcMinusFourTime: "",
      queryParam: {
        ProviderId: 0,
        GameCode: "",
        BeginDate: "",
        EndDate: "",
      },
      pickerDependent: null,
      pickerClassType: null,
      providerGameList: [],
      BeginDay: "",
      EndDay: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    updateTime() {
      var  timeZome = 0;
      timeZome = getStore("TimeZome");
      const date = new Date(); // Get current date/time
      const offset = timeZome * 60; // Offset in minutes (UTC-4 is 4 hours behind UTC)
      const utcMinusFourDate = new Date(date.getTime() + offset * 60000);
      const formattedTime = this.formatDate(utcMinusFourDate);

      this.utcMinusFourTime = formattedTime + " (UTC+5)";
    },
    formatDate(date) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "UTC",
      };

      return date.toLocaleString("en-US", options).replace(",", "");
    },
    searchSubmit() {
      this.queryParam.BeginDate = this.BeginDay + " 00:00:00";
      this.queryParam.EndDate = this.EndDay + " 23:59:59";
      if (new Date(this.queryParam.BeginDate).getTime() > new Date(this.queryParam.EndDate).getTime()) {
        this.$f7.dialog.alert(this.$t("common_0031"), this.$t("common_0004"));
        return false;
      }
      this.$f7router.navigate(`/member-center/order-records/${JSON.stringify(this.queryParam)}/`);
    },
    getUTCMinus4Date(diffDays) {
      // let now = new Date();
      // let utcTime = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      // let utcMinus4 = new Date(utcTime.getTime() - 4 * 60 * 60 * 1000);
      // utcMinus4.setDate(utcMinus4.getDate() + diffDays);
      // let year = utcMinus4.getFullYear();
      // let month = utcMinus4.getMonth() + 1;
      // let day = utcMinus4.getDate();
      // return `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;

      const now = new Date();
      now.setDate(now.getDate() + diffDays);
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    onPageBeforeRemove() {},
  },
  created() {
    this.BeginDay = this.getUTCMinus4Date(0);
    this.EndDay = this.getUTCMinus4Date(0);
  },
  mounted() {
    this.updateTime(); // Call updateTime() when the component is mounted
    this.intervalId = setInterval(this.updateTime, 1000); // Update time every second
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
<style lang="less" scoped>
.page-betting-search {
  .block-search {
    margin: 15px 10px;
    padding: 0;
    .form {
      .form-item {
        .item-inner {
          .item-title {
            color: #464646;
            font-size: 14px;
          }
          .item-input-wrap {
            // width: 100%;
            background: #fafafa;
            border: 1px solid #1b2a3b10;
            padding: 10px;
            border-radius: 6px;
            margin: 2px 0 10px;
            input {
              width: 100%;
            }
          }
          .smart-select .item-after {
            max-width: unset;
          }
        }
      }
    }
    .list {
      margin: 0;
      ul {
        background-color: transparent;
        .item-input {
          .item-inner {
            .input-filter {
              .row-item {
                .col-50 {
                  position: relative;
                }
                .col-50:first-child:after {
                  content: "";
                  position: absolute;
                  right: 2px;
                  width: 1px;
                  height: 25px;
                  background-color: #033a58;
                  bottom: 10px;
                }
                .col-50:before {
                  font-family: framework7-core-icons;
                  font-weight: 400;

                  line-height: 1;
                  letter-spacing: normal;
                  text-transform: none;
                  white-space: nowrap;
                  word-wrap: normal;
                  direction: ltr;
                  -webkit-font-smoothing: antialiased;
                  text-rendering: optimizeLegibility;
                  -moz-osx-font-smoothing: grayscale;
                  -moz-font-feature-settings: "liga";
                  font-feature-settings: "liga";
                  text-align: center;
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 50%;
                  width: 8px;
                  height: 14px;
                  margin-top: -7px;
                  font-size: 20px;
                  font-size: var(--f7-list-chevron-icon-font-size);
                  line-height: 14px;
                  color: #c7c7cc;
                  color: var(--f7-list-chevron-icon-color);
                  pointer-events: none;
                  right: calc(var(--f7-list-item-padding-horizontal) + 0px);
                  right: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-right));
                  content: "chevron_down";
                }
              }
            }
            .item-title {
              padding-top: 8px;
              width: 25%;
            }
            input {
              color: #464646;
              font-size: 14px;
            }
            input.input-title {
              color: #464646;
              font-size: 16px;
            }
          }
          .item-inner:after {
            height: 0;
          }
        }
        .item-title {
          color: #464646;
          font-size: 16px;
        }
      }
      ul:before,
      ul:after {
        height: 0;
      }
    }
  }
  .button-search {
    margin: 10px 12px;

    ul {
      background: var(--f7-button-bg-color);
      li {
        a {
          color: var(--f7-button-text-color);
        }
      }
    }
  }
  .curr-time {
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;
    color: #404040;
  }
}
</style>
