<template>
  <f7-page @page:init="onPageInit" @page:beforein="onPageBeforeIn" @page:beforeout="onPageBeforeOut" class="page-member-center" name="member-center" style="padding-bottom: var(--f7-safe-area-bottom);">
    <f7-navbar :title="$t('member_center_0001')" :back-link="false" :no-hariline="true"></f7-navbar>
    <f7-card class="card-user-info" v-if="getLoginState && userInfo.UserName" style="box-shadow: 0px 0px 0px;border-radius: 0px;">
      <f7-card-content>
        <f7-row no-gap class="row-level">
          <f7-col class="user-vip-img text-center">
            <img class="img-brand" :src="`../static/images/yt999/home/ico/ZZicon/vip-logo.png`" />
          </f7-col>
          <f7-col class="user-area">
            <div class="user-name">{{ userInfo.UserName.split(".")[1] }}</div>
            <div class="user-level">
              <img src="../../static/images/yt999/vip/vip_num.png" />
              <span>{{ userInfo.VipLevelName }}</span>
            </div>
          </f7-col>
        </f7-row>
        <f7-row class="referrerLevel" v-if="userInfo.ReferrerLevelID > 0" @click="$f7router.navigate('/member-center/myPosition/')">
          <img src="../../static/images/yt999/referrerLevel.png" />
          <span>{{ userInfo.ReferrerLevelName }}</span>
        </f7-row>
        <div v-else style="height: 0;">
          <f7-row class="referrerLevel" v-if="userInfo.IsReferrerLevelActive" @click="$f7router.navigate('/member-center/myPosition/')">
            <img src="../../static/images/yt999/referrerLevel.png" />
            <span>{{ $t("member_center_0056") }}</span>
          </f7-row>
          <f7-row v-else>
            <f7-row class="referrerLevel" v-if="enableMemberReferrerLevelUpgrade" @click="$f7router.navigate('/member-center/jobApplication/')">
              <img src="../../static/images/yt999/referrerLevel.png" />
              <span>{{ $t("jobApplication_001") }}</span>
            </f7-row>
          </f7-row>
        </div>
        
        <div class="panel-login-info">
          <f7-row no-gap>
            <f7-col width="100" class="text-left welcome">{{ $t("member_center_0002", [userInfo.VipLevelName]) }}</f7-col>
          </f7-row>
          <f7-row no-gap class="login-info">
            <f7-col width="35" class="text-left title">{{ $t("member_center_0055") }}</f7-col>
            <f7-col width="65" class="text-right detail">{{ userInfo.FirstVIPActiveTS | time_local2 }}</f7-col>
          </f7-row>
          <f7-row no-gap class="login-amt">
            <f7-col width="35" class="text-left title">{{ $t("member_center_0047") }}</f7-col>
            <f7-col width="65" class="text-right detail">
              <f7-link v-if="totalBalance > -1">
                <span class="amount">{{ userInfo.CreditScore }}</span>
                <i class="f7-icons white">chevron_right</i>
              </f7-link>
              <preloader :list="4" v-else></preloader>
            </f7-col>
          </f7-row>
          <f7-row no-gap class="login-amt" style="margin-top: -12px">
            <f7-col width="35" class="text-left title">{{ $t("member_center_0046") }}</f7-col>
            <f7-col width="65" class="text-right detail">
              <f7-link popup-open=".balanceInfo-popup" v-if="totalBalance > -1">
                <span class="symbol"><span class="symbol">{{ currencySymbol }}</span></span>
                <span class="amount">{{ totalBalance | formatCurrency }}</span>
                <i class="f7-icons white">chevron_right</i>
              </f7-link>
              <preloader :list="4" v-else></preloader>
            </f7-col>
          </f7-row>
          <!-- <f7-row no-gap class="login-amt" style="margin-top: -12px">
            <f7-col width="50" class="text-left title">{{ $t("member_center_0051") }}</f7-col>
            <f7-col width="65" class="text-right detail">
              <f7-link v-if="totalProfitAmount > -1">
                <span class="symbol"><span class="symbol">{{ currencySymbol }}</span></span>
                <span class="amount">{{ totalProfitAmount | formatCurrency }}</span>
                <i class="f7-icons white">chevron_right</i>
              </f7-link>
              <preloader :list="4" v-else></preloader>
            </f7-col>
          </f7-row> -->
        </div>
      </f7-card-content>
    </f7-card>
    <f7-card class="card-user-info" v-else style="box-shadow: 0px 0px 0px">
      <f7-card-content>
        <f7-row no-gap class="row-level">
          <f7-col class="user-vip-img text-center">
            <!-- <img class="img-brand" :src="`../static/images/yt999/merchant/${$f7.params.fileName}/logo/dlog.png`" /> -->
            <img class="img-brand" :src="`../static/images/yt999/home/ico/ZZicon/vip-logo.png`" />
            <img class="img-brand" src="" />
          </f7-col>
          <f7-col class="user-area">
            <div class="user-name">--</div>
            <div class="user-level">
              <img src="../../static/images/yt999/vip/vip_num.png" />
              <span>--</span>
            </div>
          </f7-col>
        </f7-row>
        <div class="panel-login-info">
          <f7-row no-gap>
            <f7-col width="100" class="text-left welcome">{{ $t("member_center_0002") }}</f7-col>
          </f7-row>
          <f7-row no-gap class="login-info">
            <f7-col width="35" class="text-left title">{{ $t("member_center_0003") }}</f7-col>
            <f7-col width="65" class="text-right detail">--</f7-col>
          </f7-row>
          <f7-row no-gap class="login-amt">
            <f7-col width="35" class="text-left title">{{ $t("member_center_0047") }}</f7-col>
            <f7-col width="65" class="text-right detail">
              <f7-link v-if="totalBalance > -1">
                <span class="amount">--</span>
                <i class="f7-icons white">chevron_right</i>
              </f7-link>
              <preloader :list="4" v-else></preloader>
            </f7-col>
          </f7-row>
          <f7-row no-gap class="login-amt" style="margin-top: -12px">
            <f7-col width="35" class="text-left title">{{ $t("member_center_0046") }}</f7-col>
            <f7-col width="65" class="text-right detail">
              <f7-link popup-open=".balanceInfo-popup" v-if="totalBalance > -1">
                <span class="symbol"></span>
                <span class="amount"></span>
                <i class="f7-icons" style="color: #ffffff">chevron_right</i>
              </f7-link>
              <preloader :list="4" v-else></preloader>
            </f7-col>
          </f7-row>
        </div>
      </f7-card-content>
    </f7-card>
    <!-- <f7-card class="card-no-login" v-if="!getLoginState">
      <f7-button outline href="/login/">{{ $t('member_center_0005') }}</f7-button>
    </f7-card> -->
    <div class="buttons-tab-mem-center tabs">
      <f7-link tab-link="#tab-accountCenter" href="/member-center/deposit/" tab-link-active>
        <i class="lnk-i" style="background: url(./../static/images/yt999/i_order_center.png)"></i>
        <span class="lnk-p">{{ $t("member_center_0013") }}</span>
      </f7-link>
      <f7-link tab-link="#tab-selfHelpPrefer" href="/member-center/withdraw/">
        <i class="lnk-i" style="background: url(./../static/images/yt999/i_order_record.png)"></i>
        <span class="lnk-p">{{ $t("member_center_0014") }}</span>
      </f7-link>
      <f7-link tab-link="#tab-accountList" href="/member-center/order-search/">
        <i class="lnk-i" style="background: url(./../static/images/yt999/i_deposit.png)"></i>
        <span class="lnk-p">{{ $t("app_v1_001") }}</span>
      </f7-link>
      <f7-link tab-link="#tab-personalInfo" href="/member-center/online-cs/">
        <i class="lnk-i" style="background: url(./../static/images/yt999/i_withdraw.png)"></i>
        <span class="lnk-p" style="white-space: nowrap; overflow: hidden">{{ $t("member_center_0048") }}</span>
      </f7-link>
    </div>
    <f7-tabs class="tabs-member-center">
      <f7-tab id="tab-accountCenter" class="page-content" tab-active>
        <f7-block>
          <f7-list>
            <f7-list-item v-show="userInfo.ReferrerLevelID>0" link="/member-center/myPosition/" :title="$t('member_center_0056')" classvip="icon-animation">
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">person_alt</i>
            </f7-list-item>
            <f7-list-item link="/member-center/financial-center/0/" :title="$t('member_center_0049')" classvip="icon-animation">
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">chart_bar_square_fil</i>
              <!-- <img class="img-service" /> -->
            </f7-list-item>
            <!-- <f7-list-item :link="`/member-center/vip/${userInfo.VipLevel}/`" :title="$t('member_center_0050')" classvip="icon-animation">
              <i slot="media" class='f7-icons' style="color:var(--f7-THEME-color)">person</i>
            </f7-list-item> -->
            <!-- <f7-list-item link="/member-center/sign-in/" :title="$t('member_center_0020')" class="icon-animation">
              <f7-icon slot="media" icon="iconfont icon-qiandao"></f7-icon>
            </f7-list-item> -->
            <!-- <f7-list-item link="/member-center/referrer-share/" :title="$t('member_center_0011')" class="icon-animation"> -->
            <f7-list-item link="/member-center/invite-friends/" :title="$t('member_center_0011')" classvip="icon-animation">
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">person_crop_circle_badge_plus</i>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/order-center/1/" :title="$t('member_center_0012')">
              <f7-icon slot="media" icon="iconfont icon-qingdan" style="color:#e63636;"></f7-icon>
            </f7-list-item> -->
            <!-- <f7-list-item link="/member-center/order-search/" :title="$t('member_center_0033')">
              <f7-icon slot="media" icon="iconfont icon-jilu"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/deposit/" :title="$t('member_center_0013')" class="icon-color">
              <f7-icon slot="media" icon="iconfont icon-cunkuan"></f7-icon>
            </f7-list-item>
            <f7-list-item link="/member-center/withdraw/" :title="$t('member_center_0014')" class="icon-color">
              <f7-icon slot="media" icon="iconfont icon-qukuan"></f7-icon>
            </f7-list-item> -->
            <f7-list-item link="/member-center/deposit-search/" :title="$t('member_center_0028')">
              <!-- <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">square_favorites_alt_fill</i>
            </f7-list-item>
            <f7-list-item link="/member-center/withdraw-search/" :title="$t('member_center_0029')">
              <!-- <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">square_favorites_fill</i>
            </f7-list-item>
            <f7-list-item link="/member-center/funds-search/" :title="$t('member_center_0030')">
              <!-- <f7-icon slot="media" icon="iconfont icon-churukujilu"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">arrow_right_arrow_left_circle_fill</i>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/transfer/" :title="$t('member_center_0015')" class="icon-color">
              <f7-icon slot="media" icon="iconfont icon-zhuanzhang"></f7-icon>
            </f7-list-item> -->
            <!-- <f7-list-item link="/member-center/safe/" title="保险箱" class="icon-color">
              <f7-icon slot="media" icon="iconfont icon-zhuanzhang"></f7-icon>
            </f7-list-item> -->
            <!-- <f7-list-item link="/member-center/vip-club/" class="vip" :title="$t('member_center_0016')" :after="$t('member_center_0017', [$f7.params.name])">
              <f7-icon slot="media" icon="iconfont icon--vip-"></f7-icon>
            </f7-list-item> -->
            <f7-list-item link="/member-center/member-info/tab-personal/" :title="$t('member_center_0034')">
              <!-- <f7-icon slot="media" icon="iconfont icon-yonghuxinxi"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">person_crop_rectangle_fill</i>
            </f7-list-item>
            <f7-list-item link="/member-center/bank-info/1/" :title="$t('member_center_0035')">
              <!-- <f7-icon slot="media" icon="iconfont icon-yinhangqia"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">building_columns_fill</i>
            </f7-list-item>
            <f7-list-item link="/member-center/change-login-pwd/" :title="$t('member_center_0054')">
              <!-- <f7-icon slot="media" icon="iconfont icon-xiugaimima"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">lock_shield_fill</i>
            </f7-list-item>
            <!-- <f7-list-item link="/member-center/change-security-pwd/" :title="$t('member_center_0044')">
              <f7-icon slot="media" icon="iconfont icon-xiugaimima"></f7-icon>
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">square_pencil</i>
            </f7-list-item> -->
            <f7-list-item link="/member-center/message-center/2/" :title="$t('member_center_0010')" :badge="userMessgaeList.length">
              <!-- <f7-icon slot="media" icon="iconfont icon-a-yonghuxinxigerenziliao"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">envelope_fill</i>
            </f7-list-item>
            <f7-list-item link="#" @click="openChangeLanguagePopup" :title="$t('member_center_0018')">
              <!-- <f7-icon slot="media" icon="iconfont icon-yuyan"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">globe</i>
            </f7-list-item>
            <f7-list-item link="#" @click="logout" :href="false" :title="$t('member_center_0019')" v-if="getLoginState">
              <!-- <f7-icon slot="media" icon="iconfont icon-iconfonttuijianren"></f7-icon> -->
              <i slot="media" class="f7-icons" style="color: var(--f7-THEME-color)">square_arrow_right_fill</i>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </f7-tab>
    </f7-tabs>

    <f7-popup v-show="showBalanceInfoPopup" class="balanceInfo-popup" :opened="balancePopupOpened" @popup:open="initBalanceInfo" @popup:close="closedBalanceInfo">
      <f7-page>
        <f7-navbar :title="$t('member_center_0004')">
          <f7-nav-right>
            <f7-link popup-close>
              <i class="f7-icons" style="color: white;">multiply_circle</i>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0038") }}</f7-col>
            <f7-col class="text-right"><span class="symbol">{{ currencySymbol }}</span>{{ totalBalance | formatCurrency }}</f7-col>
          </f7-row>
          <!-- <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t('member_center_0039') }}</f7-col>
            <f7-col class="text-right"><span class="symbol">{{ currencySymbol }}</span>{{walletBalance| formatCurrency}}</f7-col>
          </f7-row> -->
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0040") }}</f7-col>
            <f7-col class="text-right"><span class="symbol">{{ currencySymbol }}</span>{{ paddingBalance | formatCurrency }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0052") }}</f7-col>
            <f7-col class="text-right"><span class="symbol">{{ currencySymbol }}</span>{{ lockAmount | formatCurrency2 }}</f7-col>
          </f7-row>
          <f7-row no-gap class="block-balance">
            <f7-col class="text-left">{{ $t("member_center_0053") }}</f7-col>
            <f7-col class="text-right" v-if="lockAmount>0">{{releaseTS | time_local}}</f7-col>
            <f7-col class="text-right" v-else>- -</f7-col>
          </f7-row>
          <!-- <f7-row no-gap class="block-sign">
            <f7-col class="text-left">{{ $t('member_center_0041') }}</f7-col>
            <f7-col class="text-right"><span class="symbol">{{ currencySymbol }}</span>{{userSignAmount| formatCurrency}}</f7-col>
          </f7-row> -->
          <!-- <f7-row no-gap class="block-point">
            <f7-col class="text-left">{{ $t('member_center_0042') }}</f7-col>
            <f7-col class="text-right">{{currentBonusPoint| formatCurrency}}</f7-col>
          </f7-row> -->
        </f7-block>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<script>
import preloader from "../template/preloader";
import api from "../../config/api";
import common from "../../service/common";
import { setCookie, getCookie, getStore, removeStore, setStore } from "../../config/utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { getUserInfo, getBalancesDetail, logout, checkBonusPointWallet, userNameSignindetailDay, getUserNewsList, referrerinfo,getInfo } from "../../axios/api";
import http from "../../axios/http";

export default {
  components: {
    preloader,
  },
  props: {},
  data() {
    return {
      currencySymbol: '',
      totalBalance: -1,
      paddingBalance: 0,
      walletBalance: 0,
      currentBonusPoint: 0,
      userSignAmount: 0,
      balancePopupOpened: false,
      userMessgaeList: [],
      showBalanceInfoPopup: true,
      totalProfitAmount: -1, //总收益

      lockAmount: 0,//保证金
      releaseTS: '', //保证金释放时间
      enableMemberReferrerLevelUpgrade: false,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["userInfo", "getLoginInfo", "getLoginState"]),
  },
  methods: {
    ...mapMutations(["SETALLBALANCES"]),
    ...mapActions(["saveLoginState", "getUserInfo"]),
    getAllBalances() {
      const self = this;
      getBalancesDetail().then((data) => {
        if (data.Code === "NoError") {
          self.SETALLBALANCES(data.Data);
          self.totalBalance = data.Data.Balance;
          self.paddingBalance = data.Data.FreezeAmount;
          self.walletBalance = data.Data.Balance;

          self.lockAmount = data.Data.LockAmount;
          self.releaseTS = data.Data.ReleaseTS;
        }
      });
    },
    getInfo() {
      const self = this;
      getInfo().then((data) => {
        if (data.Code === "NoError" && data.Data) {
          self.enableMemberReferrerLevelUpgrade = data.Data.BaseInfo.EnableMemberReferrerLevelUpgrade;
        }
      });
    },
    openChangeLanguagePopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-language", true);
    },
    logout() {
      const self = this;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      self.$f7.dialog
        .create({
          title: this.$t("common_0004"),
          text: this.$t("member_center_0045"),
          cssClass: "dialog-logout",
          buttons: [
            {
              text: this.$t("common_0002"),
              cssClass: "dialog-cancel",
              close: true,
            },
            {
              text: this.$t("common_0001"),
              cssClass: "dialog-ok",
              onClick: () => {
                self.$f7.$(".dialog-backdrop").css("background", "transparent");
                logout().then((data) => {
                  common.logoutEvent(self);
                  self.saveLoginState();
                  self.$f7router.navigate("/");
                });
              },
            },
          ],
        })
        .open();
    },
    initBalanceInfo() {
      const self = this;
      self.showBalanceInfoPopup = true;
      checkBonusPointWallet().then((data) => {
        if (data.Code === "NoError") {
          self.currentBonusPoint = data.Data.CurrentBonusPoint;
        }
      });
      const param = { data: JSON.stringify({ Choose: false }) };
      userNameSignindetailDay(param).then((data) => {
        if (data.Code === "NoError") {
          self.userSignAmount = data.Data[1];
        }
      });
    },
    userMessgaeListEvent() {
      const self = this;
      const param = {
        data: JSON.stringify({
          Status: 2,
          BeginDate: null,
          EndDate: null,
          PageNow: 1,
          PageSize: "100",
        }),
      };
      getUserNewsList(param).then((data) => {
        if (data.Code === "NoError") {
          self.userMessgaeList = data.Data.Data;
        }
      });
    },
    tabInit(index) {
      setStore("mc-tab-active", index);
    },
    onPageInit() {
      this.saveLoginState();
      if (this.getLoginState) {
        this.getUserInfo();
        this.getAllBalances();
        this.userMessgaeListEvent();
        // this.getreferrerinfo();
      }
    },
    //获取总佣金
    getreferrerinfo() {
      const self = this;
      referrerinfo().then((data) => {
        if (data.Code === "NoError") {
          self.referrerinfoList = data.Data;
          self.totalProfitAmount = data.Data["TotalProfitAmount"];
        }
      });
    },
    onPageBeforeIn() {
      const self = this;
      self.$nextTick(() => {
        self.$f7.emit("myCustomEvent", "member-center");
        const tabActiveIndex = getStore("mc-tab-active") || 1;
        // if (tabActiveIndex == 1) {
        //   self.$f7.tab.show("#tab-accountCenter");
        // }
        // if (tabActiveIndex == 2) {
        //   self.$f7.tab.show("#tab-selfHelpPrefer");
        // }
        // if (tabActiveIndex == 3) {
        //   self.$f7.tab.show("#tab-accountList");
        // }
        // if (tabActiveIndex == 4) {
        //   self.$f7.tab.show("#tab-personalInfo");
        // }
        // if (tabActiveIndex == 5) {
        //   self.$f7.tab.show("#tab-message");
        // }
      });
    },
    onPageBeforeOut() {
      //removeStore('mc-tab-active');
    },
    closedBalanceInfo() {
      this.balancePopupOpened = false;
      this.showBalanceInfoPopup = false;
    },
  },
  created() {
    sessionStorage.removeItem("deposit-flag");
    this.currencySymbol = getStore('currencySymbol');
    this.getInfo();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
::v-deep .navbar-inner{    //头部颜色更换
  color: white;
  background: linear-gradient(to right, #357ae2, #1f64cd);
}
.referrerLevel{
  position: absolute;
  top: 13px;
  right: 10px;
  border-radius: 35px;
  color: white;
  background: #4f88dd;
  display: inline-flex;
  justify-content: center;
  padding: 5px !important;
  padding-right: 15px !important;
  font-size: 12px;
  img{
    width: 23px;
    margin: 0 5px;
  }
}
.user-area {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -32px;
  .user-name {
    margin-right: 4px;
    position: absolute;
    top: 2px;
    left: 0px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
  }

  .user-level {
    position: absolute;
    top: 0px;
    right: -26px;
    img {
      height: 18px;
      position: absolute;
      left: -77px;
      top: 32px;
    }
    span {
      max-width: 140px;
      color: #fff;
      position: absolute;
      top: 32px;
      font-style: italic;
      font-weight: bold;
      font-size: 12px;
      left: -50px;
      background: #00c9d0;
      border-radius: 18px;
      height: 15px;
      margin-top: 1px;
      line-height: 15px;
      padding-right: 5px;
      white-space: nowrap;
    }
  }
}
.gear {
  position: absolute;
  right: 10px;
  top: 15px;
  color: black;
}
.ios .page-member-center {
  background: #fafafa;
}

.list {
  font-size: 16px;
 
}

.img-service {
  position: absolute;
  width: 28px;
  height: 28px;
  content: url(../../static/images/yt999/home/ico/service_1.png);
}
::v-deep .list .item-title{
  color: black;
}
.f7-icons{
  font-size: 30px;
}
</style>
