<template>
  <f7-page no-swipeback name="activity" class="page-messgae-center" @page:init="onPageInit()" style="padding-bottom: var(--f7-safe-area-bottom);"
    infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <f7-navbar innerClass="navbar-inner-message">
      <f7-nav-left>
        <f7-link @click="backMemberCenter">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("activity_0022") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <!-- <div class="newMenu" >
      <van-tabs type="card">
        <van-tab :title="$t('activity_0010')"  @click="toggleMessage(0)"></van-tab>
        <van-tab :title="$t('activity_0011')"  @click="toggleMessage(1)"></van-tab>
        <van-tab :title="$t('activity_0013')" @click="toggleMessage(3)"></van-tab>
      </van-tabs>
    </div> -->
    <f7-block class="menu">
      <div style="margin: 0;" :class="{ 'activeMenu': readActiveIndex == 0 }" @click="toggleMessage(0)">
        <!-- <img :src="`../../static/images/yt999/home/ico/ZZicon/unico.png`" alt="" class="hot"> -->
        {{ $t("activity_0010") }}
      </div>
      <div :class="{ 'activeMenu': readActiveIndex == 1 }" @click="toggleMessage(1)">
        <img :src="`../../static/images/yt999/home/ico/ZZicon/hot${readActiveIndex==1? '2':'1'}.png`" alt="" class="hot">{{ $t("activity_0011") }}
      </div>
      <div :class="{ 'activeMenu': readActiveIndex == 3 }" @click="toggleMessage(3)">
        <!-- <img :src="`../../static/images/yt999/home/ico/ZZicon/zhuico.png`" alt="" class="hot"> -->
        {{ $t("activity_0013") }}
      </div>
    </f7-block>

    <f7-block v-if="readActiveIndex != 2" style=" margin-top: 58px;">
      <div class="card-message card-user-message card financial" v-for="(item, index) in financialList" :key="index">
        <div class="financialone" @click="showPopup(item)">
          <img :src="item.ImgUrl" style="border: 1px solid #ccc;border-radius: 5px;">
          <span class="nameSpan">{{ item.CnName_FinancialProductName }}</span>
          <span style="width: 70%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{
            item.CnName_SubTitle
            }}</span>
          <img src="../../static/images/yt999/home/ico/ZZicon/activityRight.png" class="toRight" height="10px">
        </div>

        <div class="financialtwo">
          <div style="width: 40%;">{{ $t("activity_0015") }}：</div>
          <div class="" style="width: 60%;text-align: right"><span class="symbol">{{ currencySymbol }}</span>{{
            item.MinSingleSubscriptionAmount | formatCurrency2 }} ~
            <span class="symbol">{{ currencySymbol }}</span>{{ item.MaxSingleSinSubscriptionAmount | formatCurrency2 }}</div>
        </div>
        <div class="financialtwo">
          <div style="width: 70%;">{{ $t("activity_0016") }}：</div>
          <div class="" style="width: 30%;text-align: right;color: var(--f7-THEME-color);"
            v-if="item.EnableFixedDailyResponseRate">{{
              item.FixedDailyResponseRate }}%</div>
          <div class="" style=" width: 30%; text-align: right;color: var(--f7-THEME-color);" v-else>{{
            item.MinDailyResponseRate }}% - {{
              item.MaxDailyResponseRate }}%</div>
        </div>
        <div class="financialtwo">
          <div style="width: 65%;font-size: 12px;" >{{ $t("activity_0027") }}：</div>
          <div style="text-align: right;width: 35%;">{{ $t("activity_0030",[item.PeriodDays]) }}({{(item.PeriodDays * item.FixedDailyResponseRate) | formatCurrency }}%)</div>
        </div>
        <div class="financialtwo">
          <div v-if="new Date() > new Date(item.EnableFrom)" style="display: flex;width: 100%;">
            <div style="width: 50%;">{{ $t("activity_0028") }}：</div>
            <div style="width: 50%;text-align: right;">{{ item.EnableTo | time2 }}</div>
          </div>
          <div v-else style="display: flex;width: 100%;">
            <div style="width: 50%;">{{ $t("activity_0029") }}：</div>
            <div style="width: 50%;text-align: right;">{{ item.EnableFrom | time2 }}</div>
          </div>
        </div>
        <div style="padding: 5px 0 5px 10px;">{{ $t("activity_0031") }}：</div>
        <div class="term">
          <div class="info">
            <div>
              <div>{{ $t("activity_0032") }}：</div>
              <div style="word-wrap: break-word;">{{ item.VIPLevelNameStr | replaceComma }}</div>
            </div>
            <div v-show="item.SubTeamMemberCountLimit > 0">
              <div>{{ $t("activity_0033") }}：</div>
              <div>≥{{ item.SubTeamMemberCountLimit }}</div>
            </div>
            <div v-show="item.CreditScoreLimit > 0">
              <div>{{ $t("activity_0034") }}：</div>
              <div>≥{{ item.CreditScoreLimit }}</div>
            </div>
          </div>
        </div>
        <div class="financialthree" style="height:37px;">

          

          <div v-if="new Date() < new Date(item.EnableFrom) ">
            <div class="downTime" v-if="((new Date(item.EnableFrom) - new Date()) / (1000 * 60 * 60)) <= 24">
              <span v-if="getcookieflag()">
                <van-count-down :time="new Date(item.EnableFrom) - new Date()" class="countdown"/> <span  style="font-size: 12px;">{{ $t("activity_0035") }}</span>
              </span>
              <span v-else>
                <span  style="font-size: 12px;">{{ $t("activity_0035") }}</span> <van-count-down :time="new Date(item.EnableFrom) - new Date()" class="countdown"/>
              </span>
            </div>
          </div>
          <div v-else-if="new Date() < new Date(item.EnableTo)">
            <div class="downTime" v-if="((new Date(item.EnableTo) - new Date()) / (1000 * 60 * 60)) <= 24">
              <span v-if="getcookieflag()">
                <van-count-down :time="new Date(item.EnableTo) - new Date()" class="countdown"/> <span style="font-size: 12px;">{{ $t("activity_0036") }}</span>
              </span>
              <span v-else>
                <span style="font-size: 12px;">{{ $t("activity_0036") }}</span> <van-count-down :time="new Date(item.EnableTo) - new Date()" class="countdown"/>
              </span>
            </div>
          </div>

          <!-- <div v-if="new Date() < new Date(item.EnableFrom)">
            <div class="downTime" v-if="((new Date(item.EnableFrom) - new Date()) / (1000 * 60 * 60)) <= 24">
              {{ downList[index] }}{{ $t("activity_0035") }}</div>
          </div>
          <div v-else-if="new Date() < new Date(item.EnableTo)">
            <div class="downTime" v-if="((new Date(item.EnableTo) - new Date()) / (1000 * 60 * 60)) <= 24">
              {{ downList[index] }}{{ $t("activity_0036") }}</div>
          </div> -->

          <div class="gobutton" @click="buyFinancial(item.FinancialProductID)">{{ $t("activity_0019") }}</div>
        </div>
      </div>
    </f7-block>
    <f7-block class="block" v-else>
      <div class="card-message card-user-message card financial" v-for="(item, index) in financialList" :key="index">
        <div class="financialone">
          <img :src="item.ImgUrl" style="border: 1px solid #ccc;border-radius: 5px;">
          <span class="nameSpan">{{ item.CnName_FinancialProductName }}</span>
          <span style="width: 70%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{
            item.CnName_SubTitle
            }}</span>
        </div>
        <div class="financialthree" style="height: 50px;">
          <div class="goDetail" @click="showPopup(item)">{{ $t("activity_0026") }}</div>
          <div class="gobutton" @click="buyFinancial(item.FinancialProductID)">{{ $t("activity_0021") }}</div>
        </div>
      </div>
    </f7-block>

    <!-- Vant -->
    <van-popup v-model="vantshow" position="bottom" closeable  class="vandetail">
      <div class="sheet-modal-swipe-step">
        <div class="display-flex justify-content-space-between align-items-center buypopup2">
          <div class="yiban one">
            <div style="margin-left: 10px;font-size: 16px;">{{ $t("activity_0037") }}</div>
          </div>
          <div class="financial" style="margin: 0px;border: 0;">
            <div class="financialone">
              <img :src="csDetail.ImgUrl" style="border: 1px solid #ccc;border-radius: 5px;">
              <span class="nameSpan">{{ csDetail.CnName_FinancialProductName }}</span>
              <span class="">{{ csDetail.EnableFrom | time2 }} ~ {{ csDetail.EnableTo | time2 }}</span>
            </div>
            <div style="padding: 10px 5px;">{{ csDetail.CnName_SubTitle }}</div>
            <div class="title" style="position: relative;">{{ $t("activity_0038") }}</div>
            <div style="padding: 10px 5px;">{{ csDetail.CnName_FinancialProductDesc }}</div>
          </div>
          <div v-if="readActiveIndex == 2" class="mylove" @click="buyFinancial(csDetail.FinancialProductID)">{{
            $t("activity_0039") }}</div>
          <div class="safebottom" ></div>
        </div>
      </div>
    </van-popup>
    
    <!-- 详情弹框 -->
    <f7-sheet class="sheet-mySheet2" style="height:auto; --f7-sheet-bg-color: #fff; border-radius: 20px 20px 0 0;"
      swipe-to-close ref="mySheet2"  swipe-to-step backdrop>
      <div class="sheet-modal-swipe-step" >
        <div class="display-flex padding justify-content-space-between align-items-center buypopup2">
          <div class="yiban one">
            <div style="margin-left: 10px;font-size: 16px;">{{ $t("activity_0037") }}</div>
          </div>
          <div class="financial" style="margin: 0px;border: 0;">
            <div class="financialone">
              <img :src="csDetail.ImgUrl" style="border: 1px solid #ccc;border-radius: 5px;">
              <span class="nameSpan">{{ csDetail.CnName_FinancialProductName }}</span>
              <span class="">{{ csDetail.EnableFrom | time2 }} ~ {{ csDetail.EnableTo | time2 }}</span>
            </div>
            <div style="padding: 10px 5px;">{{ csDetail.CnName_SubTitle }}</div>
            <div class="title" style="position: relative;">{{ $t("activity_0038") }}</div>
            <div style="padding: 10px 5px;">{{ csDetail.CnName_FinancialProductDesc }}</div>
          </div>
          <div v-if="readActiveIndex == 2" class="mylove" @click="buyFinancial(csDetail.FinancialProductID)">{{
            $t("activity_0039") }}</div>
          <div></div>
        </div>
      </div>
    </f7-sheet>
    <!-- 购买弹框 -->
    <f7-sheet class="sheet-mySheet" style="height:auto; --f7-sheet-bg-color: #fff; border-radius: 20px 20px 0 0;"
      swipe-to-close ref="mySheet" id="mySheet">
      <div class="sheet-modal-swipe-step" >
        <div class="display-flex padding justify-content-space-between align-items-center buypopup">
          <div class="yiban one">
            <div style="line-height: 37px;font-size: 16px;">{{ $t("financial_detail_0007") }}</div>
            <div style="position: relative;text-align: right;">
              <i class="f7-icons" style="color:yellow" id="add" @click="topUp()">plus_circle</i>
              <div class="yuer">
                <span class="enUs_style_money">{{ $t("financial_detail_0008") }}</span><br />
                <span><span class="symbol">{{ currencySymbol }}</span>{{ memberBalance }}</span>
              </div>
            </div>
          </div>
          <div class="paddding" style="">{{ financialDetail.CnName_FinancialProductName }}</div>
          <div class="yiban two paddding" v-show="readActiveIndex != 2">
            <div style="color:#707070 ;">{{ $t("financial_detail_0001") }}</div>
            <div style="color:#707070 ;"><span class="symbol">{{ currencySymbol }}</span>{{ financialDetail.MinSingleSubscriptionAmount }}-<span class="symbol">{{ currencySymbol }}</span>{{
              financialDetail.MaxSingleSinSubscriptionAmount }}</div>
          </div>
          <div class="inputPrice">
            <div style="padding: 0 0 10px 5px;" v-show="readActiveIndex != 2">{{ $t("financial_detail_0017") }}{{
              financialDetail.MaxSingleSinSubscriptionAmount }}</div>
            <div class="kun">
              <span class="reduce" @click="price = price - 100"><img
                  src="../../static/images/yt999/home/ico/ZZicon/reduce.png" alt="" width="18px"></span>
              <input type="text" v-model="price">
              <span class="sum" @click="price = price + 100"><img
                  src="../../static/images/yt999/home/ico/ZZicon/sum.png" alt="" width="18px"></span>
            </div>
          </div>
          <div class="paddding price" style="display: flex;justify-content: right;padding-bottom: 13px;">
            <span @click="selectPrice(100000, 1)" :class="select == 1 ? `selectBg` : ``">{{ 100000 | formatCurrency2}}</span>
            <span @click="selectPrice(2000000, 2)" :class="select == 2 ? `selectBg` : ``">{{ 2000000 | formatCurrency2}}</span>
            <span @click="selectPrice(5000000, 3)" :class="select == 3 ? `selectBg` : ``">{{ 5000000 | formatCurrency2}}</span>
            <!-- <span @click="selectPrice(1000, 4)" :class="select == 4 ? `selectBg` : ``">1000</span> -->
            <span @click="price = memberBalance; select = 5" :class="select == 5 ? `selectBg` : ``">{{
              $t("financial_detail_0010") }}</span>
          </div>
          <div class="yiban two paddding" v-show="readActiveIndex != 2">
            <div style="color:#707070 ;">{{ $t("financial_detail_0016") }}</div>
            <div style="color:#707070 ;">{{ price }}×{{ financialDetail.FixedDailyResponseRate }}%×90={{
              (price * financialDetail.FixedDailyResponseRate * 90) / 100 }}</div>
          </div>
          <div class="yiban four paddding">
            <div><span style="color: #707070;">{{ $t("financial_detail_0011") }}</span><span
                style="color: var(--f7-THEME-color);font-size: 20px;"><span class="symbol">{{ currencySymbol }}</span>{{ price }}</span></div>
            <div class="paybtn" @click="financialCreateOrder(financialDetail.FinancialProductID)">{{
              $t("financial_detail_0012") }}</div>
          </div>
        </div>
      </div>
    </f7-sheet>
  </f7-page>
</template>


<script>
import { getCookie,getStore } from "@/config/utils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getMsgSystemList, getUserNewsList, alreadyRead, memberFinancialproductlist, getBalancesDetail, createOrder } from "../../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      activeNames: ['1'],
      currencySymbol: '',
      readActiveIndex: 0,
      popupMsgOpened: false,
      popupMessageItem: [],
      showMsgPopup: true,
      showfalg: true,
      memberfinancialproductlist: [],

      //分页
      financialList: [],
      allowInfinite: true,
      showPreloader: true,
      pageNow: 1,
      pageTotal: 0,
      showData: true,
      //理财类型
      lowRiskProductsList: [],  //低风险
      highYieldProductsList: [],  //高收益
      charityDonationProductsList: [],    //慈善
      exclusiveFundProductsList: [],       //专属
      financialDetail: {},
      csDetail: {},
      //购买弹框
      memberBalance: 0,
      price: 100,
      select: 1,
      downList: [],
      intervalDown: null,
      vantshow: null,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["getFinancialDetail", "userInfo"])
  },
  methods: {
    ...mapMutations(["SETFINANCIAL_DETAIL"]),
    onPageInit() {
      this.$f7.emit("myCustomEvent", "activity");
      this.toggleMessage(this.$f7route.params.Id);
    },
    showPopup(item) {
      this.vantshow = true;
      this.csDetail = item;
    },
    getcookieflag(){
      if (getCookie('lang') =='zhTW' || getCookie('lang') =='zhCN' ){
        return true;
      }else{
        return false;
      }
    },
    
    returnDownTime(dateTime) {
      const time = new Date(dateTime);
      const curTime = new Date();
      let timeDiff = null;
      if (time >= curTime) {
        timeDiff = Math.abs(time - curTime);
      } else {
        timeDiff = Math.abs(curTime - time);
      }

      // 计算小时和分钟
      let hours = Math.floor(timeDiff / (1000 * 60 * 60));
      let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      return hours + this.$t("activity_0040") + minutes + this.$t("activity_0041");
    },
    opencsDetail(item) {
      this.csDetail = item;
      this.$refs.mySheet2.open();
    },
    buyFinancial(id) {
      // this.financialDetail = item;
      // this.$refs.mySheet.open();
      // this.SETFINANCIAL_DETAIL(item); //存储Vuex store financial_detail
      this.$f7.sheet.close();
      this.$f7router.navigate(`/member-center/financial-detail/${JSON.stringify({ 'index': this.readActiveIndex, 'financialid': id })}/`);
    },
    getFinancialList() {
      const self = this;
      const params = { "PageNow": self.pageNow, "PageSize": 20 };
      if (self.readActiveIndex == 0) {
        params.FinancialProductType = 'LowRiskProducts';
      } else if (self.readActiveIndex == 1) {
        params.FinancialProductType = 'HighYieldProducts';
      } else if (self.readActiveIndex == 2) {
        params.FinancialProductType = 'CharityDonationProducts';
      } else if (self.readActiveIndex == 3) {
        params.FinancialProductType = 'ExclusiveFundProducts';
      }
      const d = { data: JSON.stringify(params) };
      memberFinancialproductlist(d).then((data) => {
        if (data.Code === "NoError") {
          self.financialList = self.financialList.concat(data.Data[0]);
          self.showData = self.financialList.length > 0;
          self.pageTotal = data.Data[1];
          if (self.financialList.length >= self.pageTotal) {
            self.showPreloader = false;
          }
          //倒计时
          // self.downTimeFan();
          // self.intervalDown = setInterval(() => {
          //   self.downTimeFan();
          // }, 60000);
        }
      });
    },
    selectPrice(price, index) {
      this.price = price;
      this.select = index;
    },
    downTimeFan() {
      const self = this;
      const curTime = new Date();
      let timeDiff = null;
      let enableFrom = null;
      let enableTo = null;
      let downItem = null;
      this.downList = [];
      self.financialList.forEach((item, index) => {
        enableFrom = new Date(item.EnableFrom);
        enableTo = new Date(item.EnableTo);
        if (curTime < enableFrom) {
          timeDiff = Math.abs(enableFrom - curTime);
        } else if (curTime < enableTo) {
          timeDiff = Math.abs(enableTo - curTime);
        } else {
          timeDiff = 0;
        }
        // 计算小时和分钟
        let hours = Math.floor(timeDiff / (1000 * 60 * 60));
        let minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        downItem =  this.$t("activity_0042", [hours,minutes]) ;
        self.downList = self.downList.concat(downItem);
      });
    },
    getsBalancesDetail() {
      getBalancesDetail().then((data) => {
        if (data.Code === "NoError") {
          const res = data.Data;
          this.memberBalance = data.Data.Balance;
        }
      });
    },
    financialCreateOrder(productId) {
      const params = { "ProductId": productId, "OrderAmount": this.price };
      const d = { data: JSON.stringify(params) };
      createOrder(d).then((data) => {
        if (data.Code === "NoError") {
          this.getsBalancesDetail();
          data.Data = "<span style='color: #2366cc;font-weight: bold;font-size:18px;'>" + this.$t("financial_detail_0013") + "</span><br><br><span style='font-size:15px;'>" + this.$t("financial_detail_0014") + "<br>" + this.$t("financial_detail_0015") + "</span>";
        }
        this.logout(data);

      });
    },
    topUp() {
      this.$f7.sheet.close();
      this.$f7router.navigate("/member-center/deposit/");
    },
    // 支付结果弹窗
    logout(data) {
      const self = this;
      self.$f7.$(".dialog-backdrop").css("background", "rgba(0, 4, 7, 0.77)");
      self.$f7.dialog.create({
        title: this.$t(""),
        // text: this.$t("member_center_0055"),
        text: data.Data,
        cssClass: "dialog-logout",
        buttons: [
          {
            text: this.$t("common_0001"),
            cssClass: "dialog-ok",
            onClick: () => {
              self.$f7.sheet.close();
              self.$f7.$(".dialog-backdrop").css("background", "transparent");
              if (data.Code === "NoError") {
                self.$f7router.navigate('/member-center/financial-center/' + self.readActiveIndex + '/');
              }
            },
          },
        ],
      }).open();
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;

      setTimeout(() => {
        if (self.financialList.length >= self.pageTotal) {
          self.showPreloader = false;
          return;
        }
        self.pageNow += 1;
        self.getFinancialList();
        self.allowInfinite = true;
      }, 1000);
    },

    toggleMessage(index) {
      this.readActiveIndex = index;
      if (index == 0) {
        this.showfalg = true;
      } else {
        this.showfalg = false;
      }
      this.infoInit();
      this.getFinancialList();
    },
    infoInit() {
      //数据初始化
      this.financialList = [];
      this.allowInfinite = true;
      this.showPreloader = true;
      this.pageNow = 1;
      this.pageTotal = 0;
      this.showData = true;
      this.downList = [];
      this.$f7.$(".page-messgae-center").scrollTo(0, 0);    //滚动条回顶部
    },
    openCardPopup(item) {
      const self = this;
      self.popupMessageItem = item;
      self.popupMsgOpened = true;
      if (!item.IsRead) {
        alreadyRead({ data: item.Id }).then((data) => {


        });
      }
    },
    backMemberCenter() {
      this.$f7router.back();
    },
    openMsgPopup() {
      this.showMsgPopup = true;
    },
    closeMsgPopup() {
      this.popupMsgOpened = false;
      this.showMsgPopup = false;
    },
    handlePopState() {
      this.$f7.sheet.close();
      location.reload();

    }
  },
  created() {
    this.currencySymbol = getStore('currencySymbol');
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopState);
  },
  beforeDestroy() {
    clearInterval(this.intervalDown);   //清理定时器
  },
};
</script>
<style lang="less" scoped>
.page-messgae-center {
  --f7-page-toolbar-bottom-offset: 0px;

  .tabs-message {
    margin: auto;
    width: 95%;
  }

  .buttons-message {
    height: 48px;
    width: var(--f7-width-size-pt100);
    display: var(--f7-display-flex);
    justify-content: var(--f7-text-center);
    box-sizing: var(--f7-border-box);
    align-items: var(--f7-text-center);
    align-content: var(--f7-text-center);
    overflow: var(--f7-hidden);

    // background-color: #0c1939;
    // border-bottom: 1px solid #0534a0;
    a.tab-link {
      width: var(--f7-width-size-pt100);
      height: var(--f7-height-size-pt100);
      box-sizing: var(--f7-border-box);
      display: var(--f7-display-flex);
      justify-content: var(--f7-text-center);
      align-items: var(--f7-text-center);
      flex-direction: var(--f7-flex-direction);
      text-transform: var(--f7-tabbar-link-text-transform);
      font-weight: var(--f7-tabbar-link-font-weight);
      letter-spacing: var(--f7-tabbar-link-letter-spacing);
      overflow: var(--f7-hidden);
      color: #808080;
      font-size: var(--f7-px-14);
    }

    a.tab-link.tab-link-active {
      color: #464646;
      // background: url(../../../static/images/yt999/signInNav.png) no-repeat 0px
      //   0px;
      // background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
      border-bottom: 1px solid;
    }
  }

  .scroll-container::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
    //隐藏水平滚动条
  }

  .menu {
    position: fixed;
    background: #fff;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 10px 0;
    padding-left: 4%;
    width: 100%;
    margin: 0;
    font-size: 12px;
    display: flex;
    div {
      // display: inline-table;
      // min-width: 90px;
      // height: 30px;
      // line-height: 30px;
      // border: 1px solid var(--f7-THEME-color);
      // border-radius: 5px;
      // font-weight: bold;
      // text-align: center;
      // margin-left: 3px;
      // overflow: hidden;
      // padding: 0 5px;
      height: 30px;
      border: 1px solid var(--f7-THEME-color);
      border-radius: 5px;
      font-weight: bold;
      margin-left: 3px;
      width: 80px;
      height: 30px;
      white-space: normal;
      text-align: center;
      padding: 3px 15px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .hot {
      width: 27px;
      // position: relative;
      // top: 4px;
      // left: -2px;
    }

    .cis {
      width: 20px;
      height: 20px;
      position: relative;
      top: 5px;
      left: -1px;
    }

    .activeMenu {
      background: var(--f7-THEME-color);
      color: white;
    }
  }

  .tabs-message {
    position: relative;
    overflow: hidden;
    min-height: calc(100% - 52px);
    height: calc(100% - 52px);

    .tab {
      padding: 0;

      .block {
        margin: 0;
        padding: 0;

        .card-message {
          margin: 0 4px 13px 4px;
          padding: 0 5px;
          background-color: #fff;
          color: #464646;
          border: 1px solid #f3f3f3;

          i.iconfont {
            font-size: 16px;
            color: #1e56bb;
            margin-right: 6px;
          }

          .card-footer {
            justify-content: flex-end;
            color: #464646;
            font-size: 14px;
            min-height: 20px;
            padding: 7px 15px;
          }

          .card-footer:before {
            height: 0;
          }
        }

        .card-user-message {
          .card-content {
            padding: 8px 15px 0;
            font-size: 16px;
          }

          .card-footer {
            //justify-content: flex-start;
            border-top: 1px solid #e3e3e3;
            width: 95%;
            margin: auto;
            margin-top: 28px;
          }
        }

        .card-user-message:first-child {
          // margin-top: 54px;
        }
      }

      .no-data {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 80%;
      }
    }
  }
}
.newMenu{
  .van-tab__text--ellipsis{
    display: block;
  }
  .van-tabs__nav--card{
    border: 1px solid #2366cc;
  }
  .van-tabs__nav--card .van-tab.van-tab--active{
    background: var(--f7-THEME-color);
    color: white;
  }
}


.block {
  margin: 0;
  margin-top: 52px;
  margin-bottom: 50px;

}


.buttonstyle .one {
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 10px 0 0 10px !important;
}

.buttonstyle .two {
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 0 10px 10px 0 !important;
}

.financial {
  border: 1px solid #e5e5e5;
  padding-top: 5px;
  margin: 10px 0;

  .financialone {
    padding: 5px;
    height: 60px;
    // border-bottom: 1px solid #e5e5e5;

    img {
      position: absolute;
      height: 60px;
    }

    span {
      display: block;
      padding-left: 70px;
    }

    .nameSpan {
      font-weight: bold;
      color: #000;
      width: 72%;
      display: block;
      padding-left: 70px;
      margin: 0 0 3px 0;
      white-space: nowrap;
      /* 禁止换行 */
      overflow: hidden;
      /* 超出部分隐藏 */
      text-overflow: ellipsis;
      /* 超出部分显示省略号 */
      margin-top: 7px;

    }

    .toRight {
      height: 20px;
      position: absolute;
      right: 12px;
      top: 24px;
    }
  }

  .financialtwo {
    // margin: 10px 0 0 0;
    // color: #5f6165;
    padding: 0 10px;
    display: flex;
    border-bottom: 1px solid #e5e5e5;

    div {
      width: 50%;
      height: 32px;
      line-height: 32px;
    }
  }

  .term {
    width: 93%;
    margin: auto;
    border-radius: 6px;
    padding: 5px 10px;
    margin-bottom: 5px;
    background: #f8f8f8;
    .info {
      border-radius: 5px;
      font-size: 14px;

      div {
        margin-bottom: 5px;
        div:nth-child(1) {

          
        }

        div:nth-child(2) {
          // min-height: 25px;
          // line-height: 25px;
          padding: 3px 0;
          padding-left: 10px;
          background: #fff;
        }
      }
      
      
    }
    
  }

  .financialthree {
    position: relative;

    .gobutton {
      background: var(--f7-THEME-color);
      border-radius: 15px;
      color: #fff;
      text-align: center;
      height: 30px;
      min-width: 80px;
      line-height: 30px;
      position: absolute;
      right: 7px;
      bottom: 5px;
      padding: 0 10px;
    }

    .downTime {
      position: absolute;
      left: 10px;
      bottom: 9px;
      color: var(--f7-THEME-color);
      font-weight: bold;
    }

    .goDetail {
      border-radius: 15px;
      text-align: center;
      height: 30px;
      width: 90px;
      line-height: 30px;
      position: absolute;
      right: 103px;
      bottom: 5px;
      border: 1px solid #ccc;
    }

    .circulation {
      display: flex;
      font-size: 12px;

      div {
        width: 50%;
      }

      div:nth-child(1) {
        text-align: left;
      }

      div:nth-child(2) {
        text-align: right;
      }
    }
    .countdown{
      color: var(--f7-THEME-color);
      font-size: 12px;
      display: inline;
    }
  }

}

.financial-have {
  // height: 220px;
  height: 195px;

  .financialthree {
    width: 62%;
    margin-left: 15px;
    text-align: right;
    margin: 40px 10px 0px 0px;
    position: relative;

    div {
      margin-top: 6px;
    }

    .gobutton {
      background: var(--f7-THEME-color);
      border-radius: 15px;
      color: #fff;
      text-align: center;
      height: 33px;
      width: 130px;
      line-height: 33px;
      position: absolute;
      right: 0;
    }
  }
}

.no-data {
  background-color: transparent;
  box-shadow: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 50%;
}


.buypopup2 {
  display: block !important;
  padding: 0px !important;
  .financial {
    padding: 5px;

  }

  .yiban {
    display: flex;
    line-height: 37px;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .one {
    background: var(--f7-THEME-color);
    color: white;
    width: 100%;
    height: 50px;
    line-height: 50px;
  }

  .title {
    font-weight: bold;
    padding-left: 23px;
  }

  .title::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 10px;
    transform: translateX(-50%);
    width: 3px;
    height: 11px;
    background-color: var(--f7-THEME-color);
  }

  .mylove {
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: white;
    border: none;
    border-radius: 10px !important;
    margin: 0px auto;
    padding: 0 5px;
    background: radial-gradient(circle, var(--f7-THEME-color), var(--f7-THEME-color));

  }
  .safebottom{
    height:calc(var(--f7-safe-area-bottom) + 55px);
  }
}

.buypopup {
  display: block !important;
  padding: 0px !important;

  #add {
    position: absolute;
    right: 25px;
    top: 5px;
    font-size: 23px;
  }

  .yuer {
    width: 100%;
    position: absolute;
    right: 60px;
  }

  .yiban {
    display: flex;

    div:nth-child(1) {
      width: 40%;
    }

    div:nth-child(2) {
      width: 60%;
    }
  }

  .inputPrice {
    color: rgb(112, 112, 112);
    margin: 10px;

    .kun {
      border: 1px solid #ccc;
      border-radius: 5px;
      position: relative;
      height: 40px;
      margin: 0 5px;

      input {
        width: 80%;
        height: 100%;
        text-align: center;

        font-weight: bold;
        margin: 0 auto;
      }
    }

    .reduce {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sum {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .paddding {
    margin: 10px;
    padding: 5px;
    border-bottom: 1px solid #e1e0e0;
    white-space: nowrap;
    /* 禁止换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;

    /* 超出部分显示省略号 */


    div:nth-child(2) {
      text-align: right;
    }

    .selectBg {
      background: var(--f7-THEME-color);
      color: white;
    }
  }
  .price{
    span {
      width: 50px;
      height: 20px;
      display: block;
      text-align: center;
      line-height: 20px;
      border-radius: 10px;
      border: 1px solid var(--f7-THEME-color);
      margin: 0px 3px;
    }
  }
  .one {
    background: var(--f7-THEME-color);
    color: white;
    border-radius: 20px 20px 0 0;
    padding: 11px 0 7px 28px;
  }

  .four {
    border: none;
    padding: 0px 0px 15px 20px;

    span {
      border: none;
    }
  }

  .paybtn {
    text-align: center !important;
    line-height: 36px;
    border-radius: 20px;
    background: var(--f7-THEME-color);
    height: 36px;
    color: #ffffff;
    font-size: 16px;
    width: 113px !important;
    margin-left: 70px;
    position: relative;
    bottom: -4px;
  }
}

::v-deep .infinite-scroll-preloader {
  margin-top: 60px;
}
.vandetail{
  // padding-bottom: 20px;
  border-radius: 20px 20px 0 0;
}

</style>
