<template>
  <f7-page no-toolbar no-swipeback name="order-submission" class="order-submission">
    <!-- <f7-navbar title="提交订单" back-link :no-hariline="true"></f7-navbar> -->
    <f7-navbar>
      <f7-nav-left>
        <f7-link @click="backWinloserp">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("Order_0015") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <div class="top">
      <div class="statistics" v-show="1 === 0">
        <ul>
          <li>
            <img class="ico-loc" src="../../../static/images/yt999/ico_address.png" style="width: 20px; line-height: 1; margin-top: 0" />
            <p class="p-loc">{{ orderInfo.Address }}</p>
            <p class="p-mobile">{{ orderInfo.Mobile | formatPhoneNumber }}</p>
          </li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li class="p-ticketno">{{ orderInfo.TicketNo }}</li>
          <li class="p-ts">{{ orderInfo.BetTs | time_local }}</li>
          <li v-if="orderInfo.IsLuckyOrder == true" class="list-type-ico">
            <img src="../../../static/images/yt999/ticket_lucky.png" />
            <img />
          </li>
          <li v-else class="list-type-ico">
            <img src="../../../static/images/yt999/ticket_normal.png" />
            <img />
          </li>
        </ul>
      </div>
      <div class="statistics stat-item">
        <div class="row-level row no-gap">
          <div class="user-vip-img text-center col-35" style="margin-bottom: 10px">
            <img class="p-img" :src="orderInfo.ProviderGameExtensionData?orderInfo.ProviderGameExtensionData:''" />
          </div>
          <div class="p-item-detail">
            <div class="p-item-name">{{ orderInfo.GameName }}</div>
            <div class="p-item-price">
              <span>{{ orderInfo.ProviderGameName }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="statistics" style="display: none">
        <ul>
          <li style="width: 40%">{{ $t("Order_0008") }}</li>
          <li class="li-right" style="width: 50%">{{ $t("Order_0009") }}</li>
        </ul>
      </div>
      <!-- <div class="statistics">
        <ul>
          <li style="width: 65%">{{ $t("Order_0007") }}</li>
          <li class="li-right" style="width: 25%; color: #0c31bf">${{ totalBalance }}</li>
        </ul>
      </div> -->
      <div v-if="orderInfo.ShowDiffAmount" class="statistics">
        <ul>
          <li style="width: 65%; color: #ff0000">{{ $t("transfer_0018") }}</li>
          <li class="li-right" style="width: 25%; color: #ff0000">-${{ 0 - orderInfo.DiffAmountWithCurrentBalance }}</li>
        </ul>
      </div>
      <div class="statistics" v-show="1 === 0">
        <ul>
          <li style="width: 65%">{{ $t("Order_0010") }}</li>
          <li class="li-right" style="width: 25%">{{ (orderInfo.CommissionRate * 100).toFixed(2) }}%</li>
        </ul>
      </div>
      <!-- <div class="statistics" v-show="1 === 1">
        <ul>
          <li style="width: 35%">{{ $t("Order_0011") }}</li>
          <li class="li-right small" style="width: 55%">${{ orderInfo.BetAmount }} × 1 × {{ (orderInfo.CommissionRate * 100).toFixed(2) }}% = ${{ (orderInfo.BetAmount * 1 * orderInfo.CommissionRate) | formatCurrency }}</li>
        </ul>
      </div> -->
      <div class="statistics">
        <ul>
          <li style="width: 65%">{{ $t("Order_0012") }}</li>
          <li class="li-right" style="width: 25%">${{ orderInfo.APIWLAmount }}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 65%">{{ $t("Order_0013") }}</li>
          <li class="li-right" style="width: 25%">${{ totalWinlose }}</li>
        </ul>
      </div>
      <div class="statistics">
        <ul>
          <li style="width: 75%">{{ $t("Order_0014") }}</li>
          <li class="li-right" style="width: 15%">
            <span style="color: #0c31bf">{{ totalBetCount }}/{{ dailyOrderCountLimit }}</span>
          </li>
          <li class="li-step" style="width: 92%">
            <f7-progressbar :progress="(totalBetCount / dailyOrderCountLimit) * 100" style="height: 7px; border-radius: 5px; color: var(--f7-button-text-color)" />
          </li>
        </ul>
      </div>
      <!-- <div class="list list-strong-ios list-outline-ios list-dividers-ios simple-list">
            <ul>
                <li>
                <f7-progressbar color="blue" :progress="60" />
                </li>
            </ul>
        </div> -->
      <div class="copy">
        <span class="copybutton" @click="openChangeOrderPopup()">{{ $t("Order_0020") }}</span>
      </div>
    </div>

    <div class="dialog dialog-orderConfirm" id="dialog-orderConfirm">
      <div class="dialog-inner" @click="closeDialog('dialog-orderConfirm')">
        <img :src="`../static/images/yt999/lucky_${$f7.params.currLang}.png`" style="width: 100%" alt="" />
      </div>
    </div>

    <!-- 订单任务弹窗 -->
    <div class="dialog dialog-change-order" id="dialog-change-order" >
      <div class="dialog-inner">
        <div class="dialog-title">
          {{ orderInfo.EventId }}
        </div>
        <div class="dialog-text">
          <f7-block class="block-balance">
            <div class="block-content">
              <div class="statistics stat-item">
                <div class="row-level row no-gap">
                  <div class="user-vip-img text-center col-25" style="margin-bottom: 10px">
                    <img class="p-img" :src="orderInfo.ProviderGameExtensionData?orderInfo.ProviderGameExtensionData:''" />
                  </div>
                  <div class="p-item-detail">
                    <div class="p-item-name">{{ orderInfo.GameName }}</div>
                    <div class="p-item-price">
                      <span>{{ orderInfo.ProviderGameName }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tip" style="margin-bottom: 25px;">
                <span class="title">{{ $t("Order_0022") }}</span>
                <p>{{ $t("Order_0023") }}</p>
                <p>{{ $t("Order_0024") }}</p>
              </div>
              <div class="tip">
                <span class="title">{{ $t("Order_0025") }}</span>
                <p style="display: flex;">
                  <img :src="state==6?'../../../static/images/yt999/yes.png':'../../../static/images/yt999/load.gif'" width="28" srcset="">
                  <span style="color: red;font-weight: bold;line-height: 28px;margin-left: 5px;">({{state}}/6)</span>
                </p>
                <p v-if="state==1" style="min-height: 40px;">{{ $t("Order_0026") }}</p>
                <p v-if="state==2" style="min-height: 40px;">{{ $t("Order_0027") }}</p>
                <p v-if="state==3" style="min-height: 40px;">{{ $t("Order_0028") }}</p>
                <p v-if="state==4" style="min-height: 40px;">{{ $t("Order_0029") }}</p>
                <p v-if="state==5" style="min-height: 40px;">{{ $t("Order_0030") }}</p>
                <p v-if="state==6" style="min-height: 40px;">{{ $t("Order_0031") }}</p>
                <p><img :src="`../../../static/images/yt999/music/state/${state}.png`" width="100%" srcset=""></p>
              </div>
            </div>
            <f7-block-footer>
              <f7-row no-gap>
                <f7-col tag="span">
                  <f7-button large class="btn-recharge border-radius-left" @click="state==6?requestConfirmOrder():''" :style="{background:state==6?'#2366cc':'#ccc'}">{{ $t("Order_0032") }}</f7-button>
                </f7-col>
              </f7-row>
            </f7-block-footer>
          </f7-block>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { getinitorderinfo, confirmorder, getmemberwinloserp } from "../../../axios/api";
import preloader from "../../template/preloader";
import { getCookie } from "../../../config/utils";
import { setTimeout } from "timers";

export default {
  components: { preloader },
  props: {},
  data() {
    return {
      info: {},
      dateRangeDesc: this.$t("common_0034"),
      winloseParams: {
        // StartDate: 20210101,
        // EndDate: 20210712,
        DateRangeType: 1,
      },
      orderParams: {
        // StartDate: 20210101,
        // EndDate: 20210712,
        TicketNo: this.$f7route.params.Id,
      },
      showWinlose: false,
      orderInfo: {},
      winloseInfo: {},
      totalBetAmount: 0,
      totalBalance: 0,
      totalWinlose: 0,
      totalBetCount: 0,
      dailyOrderCountLimit: 0,
      totalStatistics: 0,
      totalDeposit: 0,
      totalWithdrawal: 0,
      totalBonus: 0,
      estimatedCommissionToday: 0,
      link: "",
      state: 1,
    };
  },
  watch: {},
  computed: {},
  methods: {
    format(percentage) {
      return percentage === this.dailyOrderCountLimit ? "100%" : `${percentage}%`;
    },
    requestConfirmOrder() {
      
      const self = this;
      self.$f7.dialog.close(`#dialog-change-order`);
      //Test
      // self.$f7.params.dialog.closeByBackdropClick = false;
      // self.$f7.dialog.open('#dialog-orderConfirm', true);
      // return

      const param = { data: JSON.stringify(self.orderParams) };
      self.$f7.params.dialog.closeByBackdropClick = false;
      let promtLoadingGame = self.$f7.dialog
        .create({
          title: "",
          text: this.$t("Order_0017"),
          cssClass: "dialog-preloadorder",
        })
        .open();
      confirmorder(param).then((data) => {
        setTimeout(() => {
          self.$f7.params.dialog.closeByBackdropClick = true;
          promtLoadingGame.close();
          if (data.Code === "NoError") {
            //self.orderInfo = data.Data;
            if (data.Data != null) {
              if (data.Data.ExistsPendingOrder) {
                //Show success
                self.$f7.params.dialog.closeByBackdropClick = false;
                let promtLoadingGame = self.$f7.dialog
                  .create({
                    title: "",
                    text: this.$t("Order_0004"),
                    cssClass: "dialog-preloadorder",
                  })
                  .open();

                //1.5 sec later show have new order
                setTimeout(() => {
                  self.$f7.params.dialog.closeByBackdropClick = true;
                  promtLoadingGame.close();

                  this.orderParams.TicketNo = data.Data.TargetOrderTicketNo;
                  if (data.Data.ShowLuckyPopup) {
                    //show popup then reload ticket info
                    self.$f7.params.dialog.closeByBackdropClick = false;
                    self.$f7.dialog.open("#dialog-orderConfirm", true);
                  } else {
                    // dont show popup then reload ticket info
                    self.$f7.params.dialog.closeByBackdropClick = false;
                    let promtLoadingGame = self.$f7.dialog
                      .create({
                        title: "",
                        text: this.$t("Order_0005"),
                        cssClass: "dialog-preloadorder",
                      })
                      .open();
                    setTimeout(() => {
                      self.$f7.params.dialog.closeByBackdropClick = true;
                      promtLoadingGame.close();
                      this.getTargetOrder(this.orderParams.TicketNo);
                    }, 1500);
                  }
                }, 1500);
              } else {
                self.$f7.params.dialog.closeByBackdropClick = false;
                let promtLoadingGame = self.$f7.dialog
                  .create({
                    title: "",
                    text: this.$t("Order_0004"),
                    cssClass: "dialog-preloadorder",
                  })
                  .open();
                setTimeout(() => {
                  self.$f7.params.dialog.closeByBackdropClick = true;
                  promtLoadingGame.close();
                  self.$f7router.navigate("/member-center/order-center/0/");
                }, 1500);
              }
            }
          } else if (data.Code === "BalanceNotEnough") {
            self.$f7.dialog.confirm(
              data.Data,
              this.$t("common_1025"),
              () => {
                self.$f7router.navigate("/member-center/deposit/");
              },
              () => {
                // self.$f7router.back();
              }
            );
          } else {
            //提示
            self.$f7.dialog.alert(data.Data);
          }
        }, 1800);
      });
    },
    getTargetOrder(targetOrderTicketNo) {
      const self = this;
      const param = { data: JSON.stringify({ TicketNo: targetOrderTicketNo }) };
      getinitorderinfo(param).then((data) => {
        if (data.Code === "NoError") {
          self.orderInfo = data.Data;
        } else {
          //提示
          self.$f7.dialog.alert(data.Data);
        }
      });
    },
    selectInitOrderInfo(checkPopup) {
      const self = this;
      const param = { data: JSON.stringify(self.orderParams) };
      getinitorderinfo(param).then((data) => {
        if (data.Code === "NoError") {
          if (checkPopup == true) {
            if (data.Data.ShowLuckyPopup) {
              self.$f7.params.dialog.closeByBackdropClick = false;
              self.$f7.dialog.open("#dialog-orderConfirm", true);
            } else {
              self.orderInfo = data.Data;
            }
          } else {
            self.orderInfo = data.Data;
          }
        } else {
          //提示
          self.$f7.dialog.alert(data.Data);
        }
      });
    },
    progressBarTime(){
      const self = this;
      const time1=setTimeout(() => {
        self.state = 2;
        const time1=setTimeout(() => {
          self.state = 3;
          const time1=setTimeout(() => {
            self.state = 4;
            const time1=setTimeout(() => {
              self.state = 5;
              const time1=setTimeout(() => {
                self.state = 6;
                clearTimeout(time1);
              }, self.randomNumber1());
            }, self.randomNumber2());
          }, self.randomNumber1());
        }, self.randomNumber1());
      }, self.randomNumber1());
    },
    randomNumber1(){
      const randomNumber = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000;   //随机2000-5000数字
      console.log(randomNumber);
      return randomNumber;
    },
    randomNumber2(){
      const randomNumber =  Math.floor(Math.random() * (12000 - 8000 + 1)) + 8000;  //随机8000-12000数字
      console.log(randomNumber);
      return randomNumber;
    },
    backWinloserp() {
      this.$f7router.navigate("/member-center/order-center/2/");
    },
    getDateRangeDesc() {
      const self = this;
      switch (self.winloseParams.DateRangeType.toString()) {
        case "1":
          return this.$t("common_0034");
        case "2":
          return this.$t("common_0036");
        case "3":
          return this.$t("common_0038");
        case "11":
          return this.$t("common_0035");
        case "12":
          return this.$t("common_0037");
        case "13":
          return this.$t("common_0039");

        default:
          return "--";
      }
    },
    selectMemberWinloseRP() {
      // this.$f7router.navigate(`/member-center/order-submission/`);
      const self = this;
      self.showWinlose = false;
      const param = { data: JSON.stringify(self.winloseParams) };
      getmemberwinloserp(param).then((data) => {
        if (data.Code === "NoError") {
          self.winloseInfo = data.Data;
          self.totalBetAmount = self.winloseInfo[0].TotalBetAmount;
          self.totalBalance = self.winloseInfo[0].TotalBalance;
          self.totalWinlose = self.winloseInfo[0].TotalWinlose;
          self.totalBetCount = self.winloseInfo[0].TotalBetCount;
          self.dailyOrderCountLimit = self.winloseInfo[0].DailyOrderCountLimit;

          self.showWinlose = true;
        }
      });
    },
    closeDialog(targetId) {
      //this.$f7.$(".dialog-backdrop").css("background", "transparent");
      this.$f7.params.dialog.closeByBackdropClick = true;
      this.$f7.dialog.close(`#${targetId}`);
      this.$f7.emit("onCloseDialog", targetId);
      //console.log(this.orderParams.TicketNo);
      this.selectMemberWinloseRP();
      this.getTargetOrder(this.orderParams.TicketNo);
    },
    openChangeOrderPopup() {
      const self = this;
      self.$f7.params.dialog.closeByBackdropClick = true; //点击背景关闭
      self.$f7.dialog.open("#dialog-change-order", true);
      self.progressBarTime();
    },
  },
  created() {
    this.selectInitOrderInfo(true);
    this.selectMemberWinloseRP();
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.order-submission {
  --f7-theme-color: #DA291C;
  color: #464646;
  .top {
    background: #fff;
    // border: 1px solid #bfb6b6;
    // border-radius: 4px;
    //margin: 10px;
    font-size: 14px;
    .stat-item {
      height: 80px;
    }
    .statistics {
      position: relative;
      width: 96%;
      margin: auto;
      background: #fafafa;
      border-radius: 5px;
      text-align: center;
      margin-top: 10px;
      border: 1px solid #1b2a3b10;
      .list-type-ico {
        position: absolute;
        top: -13px;
        left: 10px;
      }
      .ico-loc {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      .p-loc {
        margin: 0px 0px 0px 35px;
        font-size: 16px;
        font-weight: 600;
      }
      .p-mobile {
        margin: 5px 0px 0px 35px;
        font-size: 14px;
        color: #050505;
      }
      .p-img {
        width: 70px;
        height: 70px;
        position: absolute;
        top: 5px;
        left: 5px;
        border: 1px solid #e1e1e1;
        border-radius: 4px;
      }
      .p-ticketno {
        width: 96%;
        line-height: 26px;
        display: inline-block;
        margin-top: 0px;
        text-align: left;
        margin-left: 40px;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: -3px;
      }
      .p-ts {
        font-size: 12px;
        width: 90%;
        display: inline-block;
        text-align: left;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 6px;
      }
      .p-item-detail {
        width: calc(100% - 85px);
        margin-left: auto;
        text-align: left;
        .p-item-name {
          font-family: Cairo;
          font-size: 15px;
          font-weight: 600;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .p-item-price {
          margin-top: 8px;
          // color: #ff0000;
          span{
            display: inline-block;
            max-width: 220px;
            color: #1456b9;
            text-decoration: underline;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
      li {
        width: 100%;
        display: inline-block;
        margin-top: 10px;
        text-align: left;
        //margin-left: 10px;
        margin-bottom: 10px;
      }
      .li-right {
        text-align: right;
        margin-left: unset;
        //margin-right: 10px;
        .small {
          font-size: 12px;
        }
      }
      .li-center {
        font-weight: bold;
        text-align: center;
        margin-left: unset;
        //margin-right: 10px;
      }
      .li-step {
        margin-left: unset;
        // display:inline-block;
        // margin-bottom: 10px
      }
      .title {
        text-align: center;
        font-size: 18px;
        padding-top: 6px;
        font-weight: bold;
        color: #ad5c09;
      }
      .amount {
        text-align: center;
        font-size: 18px;
        padding-top: 6px;
        font-weight: bold;
        color: #ad5c09;
      }
      .desc {
        border-top: 1px solid #e0dbdb;
        margin-top: 10px;
        .p1 {
          margin: 10px;
          font-weight: bold;
        }
        .p2 {
          margin-left: 10px;
          margin-right: 10px;
          padding-bottom: 10px;
          word-wrap: break-word;
        }
      }
    }
    .info {
      width: 90%;
      margin: 10px auto;
      div {
        background: #f1f1f1;
        border-radius: 5px;
        height: 28px;
        line-height: 28px;
        // margin-top: 6px;
        position: relative;
        span:nth-child(1) {
          float: left;
          margin-left: 10px;
        }
        span:nth-child(2) {
          color: #002fb3;
        }
        .refer {
          background: linear-gradient(#decabc, #b19785);
          color: #000;
          height: 26px;
          border-radius: 4px;
          line-height: 28px;
          width: 50px;
          display: inline-block;
          text-align: center;
          position: absolute;
          right: 1px;
          top: 1px;
        }
      }
      .list ul:before {
        background-color: transparent;
      }
      .list ul:after {
        background-color: transparent;
      }
      .list .item-content {
        display: flex;
        justify-content: space-around;
      }
    }
    .line {
      width: 95%;
      margin: auto;
      margin-top: 15px;
      border-top: 1px solid #1b336e;
    }
    .list {
      margin: auto;
    }
    .share {
      width: 90%;
      background: #2d2d2d;
      color: #b1b9ce;
      border-radius: 15px;
      height: 30px;
      line-height: 30px;
      margin: 15px auto;
      position: relative;
      padding-left: 10px;
      .sharedesc {
        width: 96%;
        color: #9cfaff;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .sharebutton {
        width: 80px;
        height: 28px;
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        display: inline-block;
        color: #000;
        background: linear-gradient(#decabc, #b19785);
        border-radius: 15px;
      }
    }
    .copy {
      width: 95%;
      //background: #2d2d2d;
      background: var(--f7-button-bg-color);
      color: var(--f7-button-text-color);
      border-radius: 7px;
      height: 40px;
      line-height: 30px;
      margin: 25px auto;
      position: relative;
      // padding-left: 10px;
      .sharedesc {
        color: #9cfaff;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .copybutton {
        width: 100%;
        height: 36px;
        line-height: 36px;
        position: absolute;
        right: 1px;
        top: 1px;
        text-align: center;
        display: inline-block;
        // color: #fff;
        background: var(--f7-THEME-color);
        border-radius: var(--f7-list-inset-border-radius);
        font-size: 17px;
        //border-radius: 15px;
        //border: #000 solid 1px;
      }
    }
  }
  .middle {
    border-top: 1px solid #bfbfbf;
    .wallet {
      background: url("../../../static/images/yt999/referrer/icon-up.png") bottom no-repeat, url("../../../static/images/yt999/referrer/highlight.png") bottom #efefef no-repeat;
      background-size: 100%;
      padding-top: 15px;
      width: 50%;
      position: relative;
      > div {
        margin-bottom: 15px !important;
      }
      color: #101010;
      .rebtn {
        background: linear-gradient(#decabc, #b19785);
        border-radius: 4px;
        width: 82%;
        height: 30px;
        line-height: 30px;
        color: #000;
        margin: auto;
      }
    }
    .rightafter::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 2px;
      height: 128px;
      background: url("../../../static/images/yt999/navafter.png") bottom no-repeat;
      background-size: 100% 100%;
    }
  }

  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    color: #101010;
    .s-title {
      font-size: 12px;
    }
  }

}
.dialog-change-order {
    color: #464646;
    width: 86%;
    margin: 0 7%;
    background-color: #ffffff !important;
    border-radius: 14px;
    // border: 1px solid var(--f7-dialog-border-color);
    left: 0;

    .dialog-inner {
      padding: 0;
      text-align: left;

      .dialog-title {
        text-align: center;
        position: relative;
        height: 40px;
        line-height: 40px;
        color: #6f7073;
        font-size: 16px;
      }

      .dialog-title:after {
        content: "";
        position: absolute;
        z-index: 1;
        background: url(../../../static/images/yt999/light.png) no-repeat;
        background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
        height: var(--f7-height-size-2);
        width: 100%;
        display: block;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        transform-origin: 50% 100%;
        transform: scaleY(1);
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
      }

      .dialog-text {
        .block-balance {
          margin: var(--f7-margin-size-0);
          padding: var(--f7-padding-size-0);
          width: var(--f7-width-size-pt100);
          .block-header {
            color: #464646;
            position: var(--f7-postion-relative);
            padding: var(--f7-padding-size-5) var(--f7-px-16);
            font-size: var(--f7-px-13);
            margin-bottom: var(--f7-px-4);

            .row {
              margin: var(--f7-margin-size-10) 0;

              .button {
                height: 18px;
                line-height: 18px;
                color: var(--f7-color-white);
                padding: var(--f7-button-padding-vertical, 0px) var(--f7-padding-size-0);
                font-size: var(--f7-size-ps-12);
              }

              .btn-refresh {
                background-color: #49743e;
              }

              .btn-recycling {
                background-color: #9f753f;
              }
            }
          }

          .block-header:after {
            content: "";
            position: absolute;
            background: url(../../../static/images/yt999/light.png) no-repeat;
            background-size: var(--f7-size-percent-100) var(--f7-size-percent-100);
            height: var(--f7-height-size-2);
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            width: 100%;
            transform-origin: 50% 100%;
            transform: scaleY(calc(1 / 1));
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
          }

          .block-content {
            padding: var(--f7-padding-size-0) var(--f7-padding-size-10);
            overflow: hidden scroll;
            padding-bottom: 20px;

            //订单弹窗样式
            .stat-item {
              height: 80px;
            }
            .statistics {
              position: relative;
              width: 100%;
              margin: auto;
              border-radius: 5px;
              text-align: center;
              margin-top: 10px;
              .p-img {
                width: 50px;
                height: 50px;
                position: absolute;
                top: 5px;
                left: 5px;
                border: 1px solid #e1e1e1;
                border-radius: 4px;
              }
              .p-item-detail {
                width: calc(100% - 68px);
                margin-left: auto;
                text-align: left;
                .p-item-name {
                  font-family: Cairo;
                  font-size: 15px;
                  font-weight: 600;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
                .p-item-price {
                  margin-top: 3px;
                  // color: #ff0000;
                  span{
                    display: inline-block;
                    max-width: 220px;
                    color: #1456b9;
                    text-decoration: underline;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                }
              }
             
            }
            .tip{
              border: 1px solid #ccc;
              padding: 10px 10px 5px 10px;
              border-radius: 7px;
              position: relative;
              p{
                margin: 7px 0;
              }
              .title{
                position: absolute;
                top: -10px;
                background: white;
                width: 40px;
                text-align: center;
              }
            }
          }

          .block-footer {
            margin-top: 6px;

            .row.no-gap {
              --f7-grid-gap: 0px;
            }

            .button {
              height: 50px;
              line-height: 50px;
              color: var(--f7-color-white);
              font-size: var(--f7-px-16);
            }
          }

          .progressbar span {
            position: relative;
            background-color: transparent;
          }

          .progressbar:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            transform-origin: left center;
            transform: translate3d(0, 0, 0);
            display: block;
            background-color: var(--f7-progressbar-progress-color, var(--f7-theme-color));
            animation: ios-progressbar-infinite 1s linear infinite;
          }
        }
      }
    }
  }
</style>
