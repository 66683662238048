<template>
  <f7-page no-toolbar no-swipeback name="financial" class="page-center" @page:init="onPageInit">
    <f7-navbar innerClass="navbar-inner">
      <f7-nav-left>
        <f7-link @click="$f7router.back()">
          <i class="f7-icons">chevron_left</i>
        </f7-link>
      </f7-nav-left>
      <f7-nav-title>{{ $t("home_0085") }}</f7-nav-title>
    </f7-navbar>
    <!--  muted-->
    <div class="content">
      <div class="video">
        <video ref="myVideo" controls playsinline webkit-playsinline
        width="100%"
        @play="onPlay"
        @pause="onPause"></video>
      </div>

      <div class="about">
        <div class="title">{{ $t("about_0002") }}</div>
        <div class="font">{{ companyOverview }}</div>
        <div class="desc" v-html="companyIntroduction"></div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import Hls from 'hls.js';
import { getStore } from "@/config/utils";
import { getwebsiteinfoGet } from "../axios/api";

export default {
  components: {},
  props: {},
  data() {
    return {
      videoSrc: "null",
      companyOverview: null,
      companyIntroduction: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    onPageInit() {
      const self = this;
      getwebsiteinfoGet().then((data) => {
        if (data.Code === "NoError") {
          self.companyOverview= data.Data.CompanyOverview;
          self.companyIntroduction= data.Data.CompanyIntroduction;
        }
      });
      if (Hls.isSupported()) {
        const video = this.$refs.myVideo;
        const hls = new Hls();
        hls.loadSource(this.videoSrc);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // video.play();
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.videoSrc;
        video.addEventListener('canplay', () => {
          // video.play();
        });
      }
    },
    onPlay() {
      console.log('Video is play');
    },
    onPause() {
      console.log('Video is paused');
    }
  },
  created() { 
    this.videoSrc = getStore("AboutUsMediaUrl")
    
  },
  mounted() { 
    
  },
};
</script>
<style lang="less">
  .desc{
    width: 100%;
    padding: 10px;
    img {
      width: 100%; /* 全局样式 */
      height: auto; 
    }
  }
</style>
<style lang="less" scoped>
  .content{
    .about{
      padding: 10px;
      .title{
        position: relative;
        padding-left: 10px;
        font-weight: bold;
        font-size: 16px;
      }
      .title::before{
        content: '';
        position: absolute;
        top: 3px;
        left: 0px;
        transform: translateX(-50%);
        width: 3px;
        height: 15px;
        background-color: var(--f7-THEME-color);
      }
      .font{
        color: #939393;
        font-weight: normal;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 6px;
        background: #f7f7f7;
      }
      div{
        margin: 5px 0;
      }
      p{
        // margin: 5px 0;
      }
    }
    
  }
</style>